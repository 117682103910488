import { Fetch } from "../../utilities/Fetch";
import { httpGet, httpGetAsync } from "../../utilities/TZMRequest";

export class TipoMovimentoService {

	autoCompletar(query) {
		return Fetch.GetJSON(`/tipos-movimento?tipomovimento=${query}&page=0&size=10`);
	}

	listar(params) {
		return httpGet("/tipos-movimento", params);
	}

	 listarCombobox = async () =>{		
		return await httpGetAsync("/tipos-movimento/list",null);
	}
}
