
import moment from 'moment';
import { Column } from 'primereact/components/column/Column';
import { ColumnGroup } from 'primereact/components/columngroup/ColumnGroup';
import { DataTable } from 'primereact/components/datatable/DataTable';
import { Row } from 'primereact/components/row/Row';
import React from "react";
import { Cell, Legend, Pie, PieChart, ResponsiveContainer, Tooltip } from 'recharts';
import TZMAutoComplete from '../../../components/common/TZMAutoComplete';
import TZMButton from '../../../components/common/TZMButton';
import TZMCalendar from "../../../components/common/TZMCalendar";
import TZMCombobox from "../../../components/common/TZMCombobox";
import TZMForm from "../../../components/common/TZMForm";
import { toCurrency, toDate, toPercent } from "../../../components/common/TZMFormatter";
import TZMTextField from "../../../components/common/TZMTextField";
import TZMMainContent from "../../../components/main/TZMMainContent";
import { ModalRealm } from '../../../components/modal/ModalRealm';
import TZMPanel from "../../../components/panel/TZMPanel";
import TZMPanelFooter from "../../../components/panel/TZMPanelFooter";
import { Fetch } from '../../../utilities/Fetch';
import Templates from '../../../utilities/Templates';
import TZMUserData from "../../../utilities/TZMUserData";
import { UF } from '../../../utilities/UF';
import UsuarioTemplate from "../../comum/usuario/UsuarioTemplate";
import { VwClienteService } from "../../servicos/VwClienteService";
import { InadimplenciaService } from "../../servicos/InadimplenciaService";
import { RegionalService } from '../../servicos/RegionalService';
import { RelatorioService } from "../../servicos/RelatorioService";
import { UsuarioService } from '../../servicos/UsuarioService';
import { VendedorService } from "../../servicos/VendedorService";
import "./Inadimplencia.css";

const title = "Inadimplência";
const empresaNome = process.env.REACT_APP_EMPRESA_NOME;

export default class Inadimplencia extends React.Component {

	constructor(props) {
		super(props);
		moment.locale('pt-BR');
		this.state = {
			loading: false,
			activeIndex: 0,
			windowSize: 0,
			params: Object.assign({}, this.defaultParams),
			fixedSeller: false,
			fixedGerente: false,
			fixedSupervisor: false,
			filtrarGrupo: false,
			regional: {
				regional:null,
				vendedor: null, 
				gerente: null,
				supervisor: null,
			},
			registros: [],
			grupoInadimplentes: [],
			listClientes: []
		};
		this.inadimplenciaService = new InadimplenciaService();
		this.vendedorService = new VendedorService();
		this.vwClienteService = new VwClienteService();
		this.relatorioService = new RelatorioService();
		this.usuarioService = new UsuarioService();
		this.regionalService = new RegionalService();
	}

	defaultParams = {
		vencimento: [null, null],
		numero: "",
		uf: "",
		cliente: {pk: {}, descricaoAmigavel: ""}
	};

	rangeDiario() {
		let date = new Date();
		let firstDay = moment(date).format("YYYY-MM-DD");
		return [firstDay, firstDay];
	}

	componentDidMount() {
		if (TZMUserData.isAutenticado()) {
			Fetch.Get("/ping");	
			let usuario = TZMUserData.getPrincipal();		
			let regional = this.state.regional;
			
			switch (usuario.nivel) {
				case TZMUserData.REPRESENTANTE:
					if (usuario.vendedor) {
						regional.vendedor = usuario.vendedor;
						this.setState({regional, fixedSeller: true});
					}
					break; 
				case TZMUserData.GERENTE:										
					regional.gerente = usuario;										
					this.setState({regional, fixedGerente: true});
				break;
				case TZMUserData.SUPERVISOR:
					regional.supervisor = usuario;
					this.setState({regional, fixedSupervisor: true});
				break;			
			default:
				break;
			}
			this.resizeGraphs();
			window.addEventListener("resize", this.resizeGraphs);
			this.handleListGrupoInadimplentes();
		} else {
			window.location.replace("/pedidos");
		} 
	}
	
	resizeGraphs = () => {
		this.setState({windowSize: window.innerHeight - 94});
	}

	handleUpdate = (key, event) => {
		this.setState({params: {...this.state.params, [key]: event.target.value}});
	}

	dateFormat(rowData, column) {
		return toDate(rowData[column.field]);
	}

	toCurrency(rowData, column) {
		return <div style={{textAlign: "right"}}>{toCurrency(rowData[column.field])}</div>
	}

	cliente(rowData) {
		return <div>
			<div style={{textAlign: "left", marginTop: "2px"}}>{rowData.razaoSocial}</div>
			<div style={{textAlign: "left"}}>{rowData.nomeFantasia}</div>
			<div style={{textAlign: "left", marginBottom: "2px"}}>{rowData.cpfCnpjFormatado}</div>
		</div>
	}

	vendedor(rowData) {
		return <div>
			<div style={{textAlign: "center", marginTop: "2px"}}>{rowData.vendedorId} - {rowData.vendedorNome}</div>
		</div>
	}

	toPercent(rowData, column) {
		return <div style={{textAlign: "right"}}>{toPercent(rowData[column.field])}</div>
	}

	mesFormat(rowData, column) {
		return moment(rowData[column.field]).format("DD/MM/YYYY");
	}

	toggleGrupo = () => { 
		this.setState({filtrarGrupo: !this.state.filtrarGrupo});
    }

	getParams(values) {       
		let params = Object.assign({}, this.state.params);
		let regional = Object.assign({}, this.state.regional);
		
		Object.keys(params).forEach(k => params[k] != null && typeof params[k] === "string" ? params[k] = params[k].trim() : null);  
		params.filtrarGrupo = this.state.filtrarGrupo ? "true" : "false";

		if (params.cliente) {
			if (params.cliente.pk.clienteId) {
				params.cliente = params.cliente.pk;
			} else {
				params.cliente = null;
			}
		}
		if (regional.vendedor && regional.vendedor.vendedorId) {
			params.vendedor = regional.vendedor.vendedorId.vendedorId.toString();
		}		
		
		if (regional.regional) {
			params.regional = regional.regional.id; 
		}

		if (regional.gerente) {
			params.gerente = regional.gerente.id; 
		}
		if (regional.supervisor) {
			params.supervisor = regional.supervisor.id;			
		}
		
		if (values) {
			params[values] = values;
		}		
		return params;
	}

	handleListGrupoInadimplentes = () => {
		this.inadimplenciaService.grafico(this.getParams()).then((response) => {
			let retorno = [];
			response.map(function(item) {				
				return {item: Number(item.grupoDiasVencido),valorAtualizado: item.valorAtualizado};
			}).reduce(function(res,value) {
				if (!res[value.item]) {
					res[value.item] = {
						item: Number(value.item),
						valorAtualizado: 0,
						descricao: InadimplenciaService.descricaoGrupo(Number(value.item))						
					};					
					retorno.push(res[value.item])
				}
				
				res[value.item].valorAtualizado += value.valorAtualizado;	
				
				return res;
			}, {}, []); 
			this.setState({grupoInadimplentes: retorno});}
		);
	}   

	handleList = () => {
		this.inadimplenciaService.inadimplentes(this.getParams()).then((response) => {
			let retorno = [];
			let registros = response;
			registros.map(function(item) {				
				return {item: Number(item.grupoDiasVencido),valorAtualizado: item.valorAtualizado};
			}).reduce(function(res,value) {
				if (!res[value.item]) {
					res[value.item] = {
						item: Number(value.item),
						valorAtualizado: 0,
						descricao: InadimplenciaService.descricaoGrupo(Number(value.item))						
					};					
					retorno.push(res[value.item])
				}
				
				res[value.item].valorAtualizado += value.valorAtualizado;	
				
				return res;
			}, {}, []); 
			this.setState({registros,grupoInadimplentes: retorno});}
		);
	}
	

	imprimir = async () => {
		this.setState({loading: true})
		await this.relatorioService.relatorioInadimplencia(this.getParams()).then(response => {
			this.setState({loading: false})
		});

	}
	clienteTemplate(cliente) {
		return <div><i className={`fa fa-${cliente.posicao === "ATIVO" ? "check-circle" : "minus-circle"}`} /> {cliente.pk.clienteId} - {cliente.razaoSocial}</div>;
	} 

	listarGerentes = (event) => {
		this.usuarioService.autoCompletar({usuario: event.query,regional: TZMUserData.GERENTE,sort:'nome'}).then((gerentes) => this.setState({gerentes}));
	}

	listarSupervisores = (event) => {		
		let supervisores = [];
		let usuario = TZMUserData.getPrincipal();		
		switch (usuario.nivel) {
			case TZMUserData.GERENTE:
				supervisores = usuario.regionais.filter((regional) => regional.supervisor && regional.supervisor.nome.toLowerCase().includes(event.query.toLowerCase()));
				supervisores = supervisores.map(r => r.supervisor);
				supervisores = supervisores.filter((v, i, a) => a.findIndex(w => w.id === v.id) === i);				
				this.setState({supervisores})	
			break;			
		default:			
			this.usuarioService.autoCompletar({usuario: event.query,regional: TZMUserData.SUPERVISOR, sort:'nome'}).then((supervisores) => this.setState({supervisores}));
			break;
		}
	}

	listarVendedores = async (event) => {
		let vendedores = [];	
		let usuario = TZMUserData.getPrincipal();			
		switch (usuario.nivel) {
			case TZMUserData.REPRESENTANTE:
				if (usuario.vendedor) {}
				break;
			case TZMUserData.GERENTE:				
				usuario.regionais.forEach((regional) => {
					vendedores = vendedores.concat(regional.vendedores.filter((vendedor) => 
					vendedor.nome.toLowerCase().includes(event.query.toLowerCase()) ||  
					vendedor.descricaoAmigavel.toLowerCase().includes(event.query.toLowerCase())));
				});
				vendedores = vendedores.filter((v, i, a) => a.findIndex(w => w.id === v.id) === i);
				this.setState({vendedores})
			break;
			case TZMUserData.SUPERVISOR:
				if (usuario.regionais) {
					usuario.regionais.forEach((regional) => {
						vendedores = vendedores.concat(regional.vendedores.filter((vendedor) => 
						vendedor.nome.toLowerCase().includes(event.query.toLowerCase()) ||  
						vendedor.descricaoAmigavel.toLowerCase().includes(event.query.toLowerCase())));
					});
					vendedores = vendedores.filter((v, i, a) => a.findIndex(w => w.id === v.id) === i);
					this.setState({vendedores})				
				} else {
					this.setState({vendedores});
				}	
			break;			
		default:
			await this.vendedorService.autoCompletar({vendedor: event.query, sort:'nome'}).then((vendedores) => this.setState({vendedores}));
			break;
		}
	}

	listarRegionais = async (event) => {
		let regionais = [];
		let usuario = TZMUserData.getPrincipal();				
		switch (usuario.nivel) {
			case TZMUserData.REPRESENTANTE:
				if (usuario.vendedor) {}
				break;
			case TZMUserData.GERENTE:	
				regionais = usuario.regionais.filter((regional) => regional.descricao.toLowerCase().includes(event.query.toLowerCase()));				
				this.setState({regionais})
			break;
			case TZMUserData.SUPERVISOR:
				if (usuario.regionais) {
					regionais = usuario.regionais.filter((regional) => regional.descricao.toLowerCase().includes(event.query.toLowerCase()));
					this.setState({regionais})				
				} else {
					this.setState({regionais});
				}	
			break;			
		default:
			await this.regionalService.autoCompletar({descricao: event.query, sort: "descricao"}).then((regionais) => this.setState({regionais}));
			break;
		}
	}

	listClientes = async (event) => {
        await this.vwClienteService.autoComplete({cliente: event.query, sort: 'razao'}).then((listClientes) => this.setState({listClientes}));
    }

	queryAutoComplete = (event) => {       
		const params = this.state.params;
		params[event.name][event.field] = event.value;
		this.setState({params});
	}


	selectAutoComplete = (event) => {
		let params = this.state.params;
		params[event.name] = event.value;
		this.setState({params});
	}

	clearAutoComplete = (event) => {
		let params = this.state.params;
		params[event.name] = { pk: {}, [event.field]: "" };
		this.setState({params});
	}

	handleUpdateParams = (event, field, index) => {
		let params = this.state.params;
		params[field][index] = event.target.value;
		this.setState({params});
	}

	formatNumero(numero) {
		return new Intl.NumberFormat('pt-BR', {minimumFractionDigits: 2, maximumFractionDigits: 2}).format(Number(numero));
	}

	COLORS = ['#5668E7', '#57AEE3', '#8B56E2', '#D055E3', '#E38955', '#69E257'];
	RADIAN = Math.PI / 180;
	renderCustomizedLabel = ({cx, cy, midAngle, innerRadius, outerRadius, percent, index}) => {
		return (`${(percent * 100).toFixed(2)}%`);
	};

	onPieClick = (data, index) => {		
		let params = Object.assign({}, {grupoDiasVencido: data.item},this.getParams());        
		this.inadimplenciaService.inadimplentes(params).then((response) => {
			this.setState({registros: response});
		});
	}

	renderSuggestion = ()  => {
		let usuario = TZMUserData.getPrincipal();
		switch (usuario.nivel) {
			case TZMUserData.REPRESENTANTE:
				break;
			case TZMUserData.GERENTE:										
				return (<div className="ui-g-12 ui-g-nopad">
						<div className="ui-g-12 ui-fluid">
							<TZMAutoComplete  onClear={this.handleClear} onSelect={this.handleSelect} onChange={this.handleSelect}
								suggestions={this.state.regionais} dropdown completeMethod={this.listarRegionais} name="regional" field="descricao" 
								label="Regional" onUnselect={this.handleUnselect} placeholder="Descrição" value={this.state.regional.regional} />
						</div>
						<div className="ui-g-12 ui-fluid">							
							<TZMAutoComplete itemTemplate={UsuarioTemplate.usuarioTemplate} onClear={this.handleClear} onSelect={this.handleSelect} onChange={this.handleSelect}
								suggestions={this.state.gerentes} dropdown completeMethod={this.listarGerentes} name="gerente" field="descricaoAmigavel" 
								disabled={this.state.fixedGerente} label="Gerente" placeholder="E-mail, Login ou Nome" onUnselect={this.handleUnselect} value={this.state.regional.gerente} />
							
						</div>
						<div className="ui-g-12 ui-fluid">
							<TZMAutoComplete itemTemplate={UsuarioTemplate.usuarioTemplate} onClear={this.handleClear} onSelect={this.handleSelect} onChange={this.handleSelect}
								suggestions={this.state.supervisores} dropdown completeMethod={this.listarSupervisores} name="supervisor" field="descricaoAmigavel" 
								disabled={this.state.fixedSupervisor} label="Supervisor" placeholder="E-mail, Login ou Nome" onUnselect={this.handleUnselect} value={this.state.regional.supervisor} />
						</div>
					</div>
				);
			case TZMUserData.SUPERVISOR:
				return (
					<div className="ui-g-12 ui-g-nopad">
						<div className="ui-g-12 ui-fluid">
							<TZMAutoComplete  onClear={this.handleClear} onSelect={this.handleSelect} onChange={this.handleSelect}
								suggestions={this.state.regionais} dropdown completeMethod={this.listarRegionais} name="regional" field="descricao" 
								label="Regional" onUnselect={this.handleUnselect} placeholder="Descrição" value={this.state.regional.regional} />
						</div>

						<div className="ui-g-12 ui-fluid">
							<TZMAutoComplete itemTemplate={UsuarioTemplate.usuarioTemplate} onClear={this.handleClear} onSelect={this.handleSelect} onChange={this.handleSelect}
								suggestions={this.state.supervisores} dropdown completeMethod={this.listarSupervisores} name="supervisor" field="descricaoAmigavel" 
								disabled={this.state.fixedSupervisor} label="Supervisor" placeholder="E-mail, Login ou Nome" onUnselect={this.handleUnselect} value={this.state.regional.supervisor} />
						</div>	
					</div>
				);	
		default:
			return (
				<div className="ui-g-12 ui-g-nopad">
					<div className="ui-g-12 ui-fluid">
						<TZMAutoComplete  onClear={this.handleClear} onSelect={this.handleSelect} onChange={this.handleSelect}
							suggestions={this.state.regionais} dropdown completeMethod={this.listarRegionais} name="regional" field="descricao" 
							label="Regional" onUnselect={this.handleUnselect} placeholder="Descrição" value={this.state.regional.regional} />
					</div>
					<div className="ui-g-12 ui-fluid">							
						<TZMAutoComplete itemTemplate={UsuarioTemplate.usuarioTemplate} onClear={this.handleClear} onSelect={this.handleSelect} onChange={this.handleSelect}
							suggestions={this.state.gerentes} dropdown completeMethod={this.listarGerentes} name="gerente" field="descricaoAmigavel" 
							disabled={this.state.fixedGerente} label="Gerente" placeholder="E-mail, Login ou Nome" onUnselect={this.handleUnselect} value={this.state.regional.gerente} />
						
					</div>
					<div className="ui-g-12 ui-fluid">
						<TZMAutoComplete itemTemplate={UsuarioTemplate.usuarioTemplate} onClear={this.handleClear} onSelect={this.handleSelect} onChange={this.handleSelect}
							suggestions={this.state.supervisores} dropdown completeMethod={this.listarSupervisores} name="supervisor" field="descricaoAmigavel" 
							disabled={this.state.fixedSupervisor} label="Supervisor" placeholder="E-mail, Login ou Nome" onUnselect={this.handleUnselect} value={this.state.regional.supervisor} />
					</div>
				</div>
			);			
		}		
	}

	handleUnselect = (event) => {
		let regional = this.state.regional;
		if (!regional[event.name]) {
			regional[event.name] = [];
		}
		regional[event.name] = regional[event.name].filter((e) => e.id !== event.value.id);
		this.setState({regional});
	}
	
	handleClear = (event) => {
		let regional = this.state.regional;
		regional[event.name] = null;
		this.setState({regional});
	}

	handleSelect = (event) => {		
		let regional = this.state.regional;		
		regional[event.name] = event.value;
		this.setState({regional});
	}

	customTooltip = ({ active, payload, label }) => {
        if (active && payload !== null) {
          return (
            <div className="style1">
                <div className="label" style={{ fontWeight: "bold", fontSize: "14px", textAlign: "center" }} >
					{`${payload[0].name}`} :  {`${toCurrency(payload[0].payload.valorAtualizado)}`}					
                </div>                      
            </div>
          );
        }
      
        return null;
    };


	render() {
		return (
			<TZMMainContent>
				<div className="ui-g">
					<div className="ui-g-7">
						<TZMPanel header={title}>
							<TZMForm>
								<div className="ui-g">
									<div className="ui-g-4">
										<TZMCalendar label="Período de Vencimento" placeholder="Inicial" onChange={(event) => this.handleUpdateParams(event, "vencimento", 0)} value={this.state.params.vencimento[0]} />
									</div>
									<div className="ui-g-4">
										<TZMCalendar label="&nbsp;" placeholder="Final" onChange={(event) => this.handleUpdateParams(event, "vencimento", 1)} value={this.state.params.vencimento[1]} />
									</div>
									<div className="ui-g-3">
										<TZMTextField type="number" autoFocus placeholder="Número NF" label="Número" onChange={(event) => this.handleUpdate("numero", event)} value={this.state.params.numero} />
									</div>
									<div className="ui-g-1">
										<TZMCombobox value={this.state.params.uf} label="UF" onChange={(event) => this.handleUpdate("uf", event)}>{UF.list}</TZMCombobox>
									</div>
									<div className="ui-g-12 ui-g-nopad" >
										<div className="ui-g-11">
											<TZMAutoComplete itemTemplate={this.clienteTemplate} onClear={this.clearAutoComplete} name="cliente"
												field="descricaoAmigavel" onSelect={this.selectAutoComplete} suggestions={this.state.listClientes}
												completeMethod={this.listClientes} placeholder="Razão social, fantasia ou CNPJ" onChange={this.queryAutoComplete}
												value={this.state.params.cliente.descricaoAmigavel} label="Cliente" />                                        
										</div>
										<div className="ui-g-1 center-button">
											<TZMButton id="warehouse1" title={this.state.filtrarGrupo ? "Filtrar apenas o cliente selecionado" : "Filtrar todos os clientes do mesmo Grupo Empresarial"}
													style={{ float: "left", color: this.state.filtrarGrupo ? '#4CAF50' : '#607D8B' }} onClick={this.toggleGrupo} secondary="true"
													icon={this.state.filtrarGrupo ?  "fas fa-users " : "fas fa-user-tie"} />								
										</div>    
									</div>
									{this.renderSuggestion()}
									<div className="ui-g-12">
										<span className="ui-fluid">
											<TZMAutoComplete itemTemplate={Templates.vendedorTemplateOmega} onClear={this.handleClear} onSelect={this.handleSelect} onChange={this.handleSelect}
													suggestions={this.state.vendedores} dropdown completeMethod={this.listarVendedores} name="vendedor" field="descricaoAmigavel" 
													disabled={this.state.fixedSeller} label="Vendedor" placeholder="Código Omega, E-mail ou Nome" onUnselect={this.handleUnselect} 
													value={this.state.regional.vendedor} />
										</span>	
									</div>
								</div>
								<TZMPanelFooter>
									<TZMButton icon="fa fa-search" label="Procurar" className="ui-button-primary" onClick={this.handleList} />
									<TZMButton icon={this.state.loading ? `fa fa-spin fa-circle-notch` : `fa fa-print`} disabled={this.state.loading} label="Imprimir" className="ui-button-primary" onClick={this.imprimir} />
									{/* <Button icon={this.state.loading ? `fa fa-spin fa-circle-notch` : `fa fa-print`} disabled={this.state.loading} label="CSV" className="ui-button-primary" onClick={this.csv} />  */}
								</TZMPanelFooter>

							</TZMForm>
						</TZMPanel>

					</div>

					<div className="ui-g-5">
						<TZMPanel header="Duplicatas Vencidas">
							<ResponsiveContainer height={Math.round(this.state.windowSize) / 2.1} width="100%">
								<PieChart onMouseEnter={this.onPieEnter} >
									<Pie data={this.state.grupoInadimplentes} animationEasing='ease-out' dataKey='valorAtualizado'
										nameKey='descricao'
										isAnimationActive={true}
										legendType='square'
										onClick={this.onPieClick}
										label={this.renderCustomizedLabel} fill="#8884d8">
										{this.state.grupoInadimplentes.map((entry, index) =>
											<Cell key={Math.random()} fill={this.COLORS[index % this.COLORS.length]} />)
										}
									</Pie>
									<Tooltip content={this.customTooltip} />
									<Legend layout='vertical' align='right' verticalAlign='middle' />
								</PieChart>
							</ResponsiveContainer>
						</TZMPanel>
					</div>
					<div className="ui-g-12">
						<TZMPanel header={<div>
							<div className="ui-g-6" style={{textAlign: "left"}}> Resultado da Pesquisa</div>
							<div className="ui-g-6" style={{textAlign: "right"}}>Total Vencidos:  {toCurrency(this.state.registros.map((i) => i.valorAtualizado).reduce((a, b) => a + b, 0))}</div>
						</div>} >
							<DataTable emptyMessage="Nenhum registro encontrado" value={this.state.registros} rows={50} rowsPerPageOptions={[50, 75, 100]} paginator={true} pageLinkSize={10}
								footerColumnGroup={this.state.registros && this.state.registros.length > 0 ?
									<ColumnGroup>
										<Row>
											<Column colSpan={5} style={{fontWeight: "bold", textAlign: "left"}} footer={`Total de registros:    ` + this.state.registros.length} />
											<Column style={{fontWeight: "bold", fontSize: "12px", textAlign: "right"}} footer="Total Vencidos " />
											<Column style={{fontWeight: "bold", textAlign: "right"}} footer={toCurrency(this.state.registros.map((i) => i.valorAtualizado).reduce((a, b) => a + b, 0))} />
											<Column colSpan={4} />
										</Row>
									</ColumnGroup>
									: null}>
								<Column field="nomeFantasia" body={this.cliente} columnKey="nomeFantasia" header="Cliente (Razão Social, Fantasia e CNPJ)" style={{width: "17em", textAlign: "left"}} sortable />
								<Column key="email" header="E-Mail" style={{width: "13em", textAlign: "center"}} body={(registro) => registro.email.split("\r").map(e => <div key={Math.random()} >{e}</div>)} />
								<Column field="pedidoId" key="pedidoId" header="Pedido" style={{width: "6em", textAlign: "center"}} sortable />
								<Column field="numero" header="Número NF" key="numero" style={{width: "4em", textAlign: "right"}} sortable />
								<Column field="dataEmissao" key="dataEmissao" header="Emissao" body={this.mesFormat} style={{width: "5em", textAlign: "center"}} sortable />
								{/* <TZMColumn field="razaoSocial" key="razaoSocial" header="Razão Social" style={{width: "15em", textAlign: "center"}} sortable /> */}
								<Column field="dataVencimento" header="Vencimento" key="dataVencimento" body={this.mesFormat} style={{width: "5em", textAlign: "center"}} sortable />
								<Column field="valorAtualizado" body={this.toCurrency} key="valorAtualizado" header="Valor" style={{width: "7em", textAlign: "center"}} sortable />
								{/* <TZMColumn field="cpfCnpjFormatado" key="cpfCnpjFormatado" header="CNPJ" style={{width: "5em", textAlign: "center"}}/> */}
								<Column field="desdobramento" key="desdobramento" header="Desdobramento" style={{width: "6em", textAlign: "center"}} />
								<Column field="diasVencido" key="diasVencido" header="Dias Vencido" style={{width: "4em", textAlign: "center"}} sortable />
								<Column field="estado" key="estado" header="Estado" style={{width: "3em", textAlign: "center"}} sortable />
								<Column body={this.vendedor} field="vendedorNome" key="vendedorNome" header="Vendedor" style={{width: "8em", textAlign: "center"}} sortable />

							</DataTable>
						</TZMPanel>
					</div>
				</div>
				<span className="ui-tzm-dashboard-title">{empresaNome} - {title}</span>
				<ModalRealm />
			</TZMMainContent>
		);
	}

}
