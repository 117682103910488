import React from "react";
import TZMButton from "../../../components/common/TZMButton";
import TZMForm from "../../../components/common/TZMForm";
import TZMTextField from "../../../components/common/TZMTextField";
import TZMConfirm from "../../../components/dialog/TZMConfirm";
import TZMMainContent from "../../../components/main/TZMMainContent";
import { ModalRealm } from "../../../components/modal/ModalRealm";
import TZMPanel from "../../../components/panel/TZMPanel";
import TZMPanelFooter from "../../../components/panel/TZMPanelFooter";
import TZMColumn from "../../../components/table/TZMColumn";
import TZMTable from "../../../components/table/TZMTable";
import TZMUserData from "../../../utilities/TZMUserData";

import { EditarSegmento } from "./EditarSegmento";
import { SegmentoService } from "../../servicos/SegmentoService";
import { FamiliaService } from "../../servicos/FamiliaService";

const title = "Segmentos";
const empresaNome = process.env.REACT_APP_EMPRESA_NOME;

export class Segmento extends React.Component {

	state = {
		desativados: false,
		segmento: {			
			descricao: ""
		}
    };

	segmentoService = new SegmentoService();
	familiaService = new FamiliaService();

	listarSegmentos = () => {
		let segmento = this.state.segmento;
		let params = Object.assign({}, segmento);		
		params.sort = "descricao";
		this.segmentoService.listarSimples(params).then((segmentos) => this.setState({segmentos}));
	}


    alternarOcultos = () => {
		this.setState({desativados: !this.state.desativados});
	}

	editarSegmento = (event) => {
		this.setState({selectedSegmento: event.data});
		if (event.data != null) {
			if (event.data.id != null) {
				this.segmentoService.completar(event.data).then((segmento) => {
					ModalRealm.showDialog(<EditarSegmento key={TZMUserData.getIdGenerator()} segmento={segmento}  onModalClose={this.listarSegmentos}  />);
				});
			} else {
				ModalRealm.showDialog(<EditarSegmento key={TZMUserData.getIdGenerator()} segmento={event.data} onModalClose={this.listarSegmentos}  />);
			}
		}
	}

	criarSegmento = () => {
		this.setState({selectedSegmento: null});
		this.editarSegmento({data: {descricao: ""}});
	}

	excluirSegmento = (segmento) => {
		ModalRealm.showDialog(<TZMConfirm onYes={() => {
			this.segmentoService.excluir(segmento).then(this.listarSegmentos);
		}} question="Tem certeza de que deseja excluir este segmento?" />);
	}
	
	handleClear = (event) => {
		let segmento = this.state.segmento;
		segmento[event.name] = null;
		this.setState({segmento});
	}

	handleSelect = (event) => {
		let segmento = this.state.segmento;
		segmento[event.name] = event.value;
		this.setState({segmento});
	}

	handleUnselect = (event) => {
		let segmento = this.state.segmento;
		if (!segmento[event.name]) {
			segmento[event.name] = [];
		}
		segmento[event.name] = segmento[event.name].filter((e) => e.id !== event.value.id);
		this.setState({segmento});
	}

	handleUpdate = (event) => {
		let segmento = this.state.segmento;
		segmento[event.name] = event.target.value;
		this.setState({segmento});
	}
    
    columns = [
		<TZMColumn header="Descrição" key={0} field="descricao" style={{width: "*"}} />,		
		<TZMColumn header="Ações" key={3} style={{width: "6em", textAlign: "center"}} body={(segmento) => {
			return (
				<div>
					{TZMUserData.hasRole("____SEGM_E") ? <span title="Editar Segmento" className="fa fa-edit ui-tzm-icon" onClick={() => this.editarSegmento({data: segmento})} /> : null}
					{TZMUserData.hasRole("____SEGM_D") ? <span title="Remover Segmento" className="fa fa-trash-alt ui-tzm-icon" onClick={() => this.excluirSegmento(segmento)} /> : null}
				</div>
			);
		}} />
	];

    render() {
		return (
			<TZMMainContent>
				<div className="ui-g">
					<div className="ui-g-12">
						<TZMPanel header={title}>
							<TZMForm>
								<div className="ui-g">
									<div className="ui-g-12">
										<TZMTextField name="descricao" autoFocus onChange={this.handleUpdate} label="Descrição" placeholder="Descrição" value={this.state.segmento.descricao} />
									</div>									
								</div>															
							</TZMForm>
							<TZMPanelFooter>
								<TZMButton title={this.state.desativados ? "Ocultar Desativados" : "Exibir Desativados"} style={{float: "left"}} onClick={this.alternarOcultos} secondary icon={this.state.desativados ? "fas fa-eye": "fas fa-low-vision"} />
								{TZMUserData.hasRole("____SEGM_C") ? <TZMButton success icon="fas fa-plus" label="Novo Segmento" onClick={this.criarSegmento} /> : null}
								<TZMButton icon="fas fa-search" label="Procurar" onClick={this.listarSegmentos} />
							</TZMPanelFooter>
						</TZMPanel>
						<div style={{height: "8px"}} />
						<TZMPanel header="Resultado da Pesquisa">
							<TZMTable value={this.state.segmentos} paginator rows={50} children={this.columns} />
						</TZMPanel>
					</div>
				</div>
			
				<span className="ui-tzm-dashboard-title">{empresaNome} - {title}</span>
				<ModalRealm />
			</TZMMainContent>
		);
	}
}
