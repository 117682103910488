import { httpDelete, httpGet, httpPost, httpGetAsync } from "../../utilities/TZMRequest";
export class SegmentoService {

    completar(segmento) {
        return httpGet(`/segmentos/${segmento.id}`);
    }

    listar(params) {
        return httpGet("/segmentos", params);
    }

    listarSimples = async (params) => {
        return await httpGetAsync("/segmentos/listar-simples", params, true);
    }

    salvar(segmento) {
        return httpPost("/segmentos", segmento);
    }

    excluir(segmento) {
        return httpDelete("/segmentos", segmento.id);
    }
    
    autoCompletar = async (params) => {
        return await httpGetAsync("/segmentos/listar-simples", params, false);
    }
}
