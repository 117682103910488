import React from "react";
import { toCurrency, toDecimal } from "../../../components/common/TZMFormatter";
import TZMPanel from "../../../components/panel/TZMPanel";
import { PrevisaoLancamentoService } from "../../servicos/PrevisaoLancamentoService";
import "./Pedido.css";

export default class PrevisaoLancamento extends React.Component {
 
	constructor(props) { 
		super(props);
		this.state = {
			previsaoLancamentoRFID: {
				totalLancadoMes: 0,
				lancadoHojeMes: 0,
				lancadoHojeFuturo: 0,
				lancadoHojeExportacao: 0,
				lancadoHojeNacional: 0,
				faturado: 0,
				carteira: 0,
				bloqueado: 0,
				faturadoDinheiro: 0,
				carteiraDinheiro: 0,
				bloqueadoDinheiro: 0,
				cotacao: 0,
				totalGeralEmM2: 0,
				totalGeralEmR$: 0,
				pedidosEmAtraso: 0,
				pedidosProntos: 0,
				pedidosProntosEmAtraso: 0
			}, 
			regional:props.regional
			
		};
		this.previsaoLancamentoService = new PrevisaoLancamentoService();
	}

	runSearch = (functionName) => {
		if (this.props[functionName]) {
			this.props[functionName]();
		}
	}

	atualizarPrevisao = () => {
		let regional = Object.assign({}, this.state.regional);
		let params = {};		
		if (regional.vendedor && regional.vendedor.id){
			params.vendedor = regional.vendedor.vendedorId.vendedorId;
		}
		if (regional.gerente){
			params.gerente = regional.gerente.id;
		}
		if (regional.supervisor){
			params.supervisor = regional.supervisor.id;			
		}		
		this.previsaoLancamentoService.previsaoLancamentoRFID(params).then((response) => {			
			this.setState({ previsaoLancamentoRFID: response, loading: false });
		});
	}

	render() {
		return (
			<TZMPanel header="Previsão de Lançamentos" >
				<div style={{cursor: "pointer", textAlign: "center", padding: "4px"}} onClick={this.atualizarPrevisao}>
					<i className="fa fa-sync-alt" /> Atualizar
				</div>
				<div className="ui-g">
					{this.state.loading ? <div className="ui-g-12" style={{textAlign: "center"}}><i className="fa fa-spinner fa-spin" style={{fontSize: "24px !important"}} /></div> : null}
					<div className="ui-g-12" style={{textAlign: "center"}}>Total Lançado Hoje para o Mês</div>
					<div className="ui-g-12" style={{textAlign: "center", fontWeight: "bold"}}>
						{toDecimal(Math.round(this.state.previsaoLancamentoRFID.lancadoHojeMes))} mi
					</div>
					<div className="ui-item-separator" />										
					<div className="ui-g-7">Lançado hoje para o mês</div>
					<div className="ui-g-5" style={{textAlign: "right"}}>
						<span className="fake-link ui-previsao-link" onClick={() => this.runSearch("onLancadoHojeMes")}>{toDecimal(Math.round(this.state.previsaoLancamentoRFID.lancadoHojeMes))} mi</span>
					</div>
					<div className="ui-g-7">Lançado hoje para o futuro</div>
					<div className="ui-g-5" style={{textAlign: "right"}}>
						<span className="fake-link ui-previsao-link" onClick={() => this.runSearch("onLancadoHojeFuturo")}>{toDecimal(Math.round(this.state.previsaoLancamentoRFID.lancadoHojeFuturo))} mi</span>
					</div>
					<div className="ui-item-separator" />
					<div className="ui-g-7">Lançado hoje exportação</div>
					<div className="ui-g-5" style={{textAlign: "right"}}>
						<span className="fake-link ui-previsao-link" onClick={() => this.runSearch("onLancadoHojeExportacao")}>{toDecimal(Math.round(this.state.previsaoLancamentoRFID.lancadoHojeExportacao))} mi</span>
					</div>
					<div className="ui-g-7">Lançado hoje nacional</div>
					<div className="ui-g-5" style={{textAlign: "right"}}>
						<span className="fake-link ui-previsao-link" onClick={() => this.runSearch("onLancadoHojeNacional")}>{toDecimal(Math.round(this.state.previsaoLancamentoRFID.lancadoHojeNacional))} mi</span>
					</div>
					<div className="ui-item-separator" />
					<div className="ui-g-7">Faturado no mês</div>
					<div className="ui-g-5" style={{textAlign: "right"}}>
						<span className="fake-link ui-previsao-link" onClick={() => this.runSearch("onFaturado")}>{toDecimal(Math.round(this.state.previsaoLancamentoRFID.faturado))} mi</span>
					</div>
					<div className="ui-g-7"></div>
					<div className="ui-g-5" style={{textAlign: "right"}}>
						<span className="fake-link ui-previsao-link" onClick={() => this.runSearch("onFaturado")}>{toCurrency(Math.round(this.state.previsaoLancamentoRFID.faturadoDinheiro))}</span>
					</div>
					<div className="ui-g-7">Carteira no mês</div>
					<div className="ui-g-5" style={{textAlign: "right"}}>
						<span className="fake-link ui-previsao-link" onClick={() => this.runSearch("onCarteira")}>{toDecimal(Math.round(this.state.previsaoLancamentoRFID.carteira))} mi</span>
					</div>
					<div className="ui-g-7"></div>
					<div className="ui-g-5" style={{textAlign: "right"}}>
						<span className="fake-link ui-previsao-link" onClick={() => this.runSearch("onCarteira")}>{toCurrency(Math.round(this.state.previsaoLancamentoRFID.carteiraDinheiro))}</span>
					</div>

					<div className="ui-g-7">Bloqueado no mês</div>
					<div className="ui-g-5" style={{textAlign: "right"}}>
						<span className="fake-link ui-previsao-link" onClick={() => this.runSearch("onBloqueado")}>{toDecimal(Math.round(this.state.previsaoLancamentoRFID.bloqueado))} mi</span>
					</div>
					<div className="ui-g-7"></div>
					<div className="ui-g-5" style={{textAlign: "right"}}>
						<span className="fake-link ui-previsao-link" onClick={() => this.runSearch("onBloqueado")}>{toCurrency(Math.round(this.state.previsaoLancamentoRFID.bloqueadoDinheiro))}</span>
					</div>

					<div className="ui-g-7">Total geral no mês</div>
					<div className="ui-g-5" style={{textAlign: "right"}}>
						<span className="fake-link ui-previsao-link" onClick={() => this.runSearch("onTotalGeralM2")}>{toDecimal(Math.round(this.state.previsaoLancamentoRFID.totalGeralEmM2))} mi</span>
					</div>
					<div className="ui-g-7"></div>
					<div className="ui-g-5" style={{textAlign: "right"}}>
						<span className="fake-link ui-previsao-link" onClick={() => this.runSearch("onTotalGeralR$")}>{toCurrency(Math.round(this.state.previsaoLancamentoRFID.totalGeralEmR$))}</span>
					</div>
					<div className="ui-item-separator" />
					<div className="ui-g-7">Pedidos em atraso</div>
					<div className="ui-g-5" style={{textAlign: "right"}}>
						<span className="fake-link ui-previsao-link" onClick={() => this.runSearch("onPedidosAtraso")}>{toDecimal(Math.round(this.state.previsaoLancamentoRFID.pedidosEmAtraso))} mi</span>
					</div>
					<div className="ui-g-7">Pedidos prontos</div>
					<div className="ui-g-5" style={{textAlign: "right"}}>
						<span className="fake-link ui-previsao-link" onClick={() => this.runSearch("onPedidosProntos")}>{toDecimal(Math.round(this.state.previsaoLancamentoRFID.pedidosProntos))} mi</span>
					</div>
				</div>
			</TZMPanel>
		);
	}
}
