import { Checkbox } from "primereact/components/checkbox/Checkbox";
import { Column } from "primereact/components/column/Column";
import { DataTable } from "primereact/components/datatable/DataTable";
import React from "react";
import TZMButton from "../../../components/common/TZMButton";
import TZMCombobox from "../../../components/common/TZMCombobox";
import TZMForm from "../../../components/common/TZMForm";
import { toDecimal } from "../../../components/common/TZMFormatter";
import TZMTextField from "../../../components/common/TZMTextField";
import TZMConfirm from "../../../components/dialog/TZMConfirm";
import TZMDialog from "../../../components/dialog/TZMDialog";
import TZMPopup from "../../../components/dialog/TZMPopup";
import { ModalRealm } from "../../../components/modal/ModalRealm";
import TZMPanel from "../../../components/panel/TZMPanel";
import TZMPanelFooter from "../../../components/panel/TZMPanelFooter";
import TZMUserData from "../../../utilities/TZMUserData";

import { ListaPrecoVersaoService } from "../../servicos/ListaPrecoVersaoService";
import { PrecoPadrao } from "./PrecoPadrao";

export class ConsultarProduto extends React.Component {

	constructor(props) {
        super();
		this.state = {
            listaPrecoVersao: props.listaPrecoVersao,
            selecionarTodos:false,
            produtosExistentes: [],
            produtosSelecionados: [],                          
            params: {
                produto: null,
                comprimentoRolo: null,
                material: null,
                diametroTubete: null,
                sentidoRebobinagem: null,                
                versao: null,
                produtoAtivo: true
            },
            visible: true,
            produtos:[]
        };        
        
        this.listaPrecoVersaoService = new ListaPrecoVersaoService(); 
        this.selecionarTodos = this.selecionarTodos.bind(this); 
        this.rowClassName = this.rowClassName.bind(this);
        this.onRowClick = this.onRowClick.bind(this);     
    }

    onHide = () => this.setState({ visible: false });

    componentDidMount() {
        let itens = this.state.listaPrecoVersao.itens;        
        let produtosExistentes = [];
        itens.forEach(item  => produtosExistentes.push(item.produto.pk.produtoId));        
        this.setState({produtosExistentes});
    }
    
    handleCombobox = (event) => {
		let params = this.state.params;
		params[event.name] = event.target.value;
		this.setState({ params });
    }

    handleUpdate = (key, event) => {
		let params = this.state.params;
		params[key] = event.target.value;
		this.setState({params});
    }
    
    checkNullArray(value) {
		return value ? value : [];
	}

    handleList = async () => {
        await this.listaPrecoVersaoService.listarProdutosListaPreco(this.state.params).then((produtos) => {
            let produtosExistentes = this.state.produtosExistentes;
            if (produtosExistentes && produtosExistentes.length > 0 ) {
                produtos = produtos.filter(p => !produtosExistentes.includes(p.pk.produtoId));
            }
            this.setState({produtos})
        });
    }
    
    confirmarSelecao = () => {
        ModalRealm.showDialog(<TZMConfirm key={TZMUserData.getIdGenerator()} onYes={this.onSave} question={'Deseja adicionar os produtos selecionados?'} />);	
    }

    onNo = () => {        
        let produtos = this.state.produtosSelecionados;    
        if (this.props.onSave) {            
            this.props.onSave(produtos);
        }
        this.onHide();
    }

    onYes = () => {
        ModalRealm.showDialog(<PrecoPadrao key={TZMUserData.getIdGenerator()} validar={true} onSave={(params) => {               
            let produtos = this.state.produtosSelecionados;
            if (this.props.onSave) {            
                this.props.onSave(produtos, params);
            }
            this.onHide();
        }} />);
    }
    
    onSave = () => {
        ModalRealm.showDialog(<TZMConfirm key={TZMUserData.getIdGenerator()} question={'Deseja atribuir um preço padrão?'}  onYes={this.onYes} onNo={this.onNo} />);
    }   

    adicionarSelecionados = () => { 
        if (this.validarSelecao()) {
            this.confirmarSelecao()
        }
    }
    
    validarSelecao = () => {
		let messages = [];
		let produtosSelecionados = this.state.produtosSelecionados;	
		if (produtosSelecionados == null || produtosSelecionados.length === 0) {
			messages.push("Nenhum produto selecionado.");
		}		
		if (messages.length > 0) {
			ModalRealm.showDialog(<TZMPopup key={TZMUserData.getIdGenerator()} header="Advertência" messages={messages} />);
			return false;
		}
		return true;
    }
    
    selecionavel(produto) {
        return produto.posicao === 'ATIVO';
    }
    
    todosItensSelecionados() {
        return this.state.produtos.filter(x => this.selecionavel(x));
    }  

    isSelecionado(item) {
        return (this.state.produtosSelecionados.includes(item));
    }

    selecionarTodos(selecionarTodos) {
        const selection = selecionarTodos ? this.todosItensSelecionados() : [];
        this.setState({selecionarTodos: selecionarTodos, produtosSelecionados: selection });
    }

    onRowClick(e) {
        const item = e.data;
        if (this.selecionavel(item)) {
          this.toggleSelection(item);
        }
    }

    toggleSelection(item) {
        let selection;
        if (this.isSelecionado(item)) {
          selection = this.state.produtosSelecionados.filter(x => x !== item);
        } else {
          selection = [...this.state.produtosSelecionados, item];
        }
        const selecionarTodos = selection.length === this.todosItensSelecionados().length;
        this.setState({ selecionarTodos: selecionarTodos, produtosSelecionados: selection });
    }

    rowClassName(item) {
        return { 'ui-state-highlight' : this.isSelecionado(item) };
    }    
    
	render() {
        return (
        <TZMDialog style={{ width: "1200px" }} visible={this.state.visible} modal header="Consultar Produto" onHide={this.onHide} {...this.props}>
            <TZMPanel>
                <TZMForm>
                    <div className="ui-g">
                        <div className="ui-g-2">
                            {/* <TZMTextField min={0} type="number" onChange={(event) => this.handleUpdate("fichaTecnicaId", event)} label="Ficha Técnica" value={this.state.params.fichaTecnicaId} /> */}
                        </div>
                        <div className="ui-g-2">
                            <TZMTextField min={0} type="number" onChange={(event) => this.handleUpdate("versao", event)} label="Versão" value={this.state.params.versao} />
                        </div>

                        <div className="ui-g-2">                            
                            <TZMTextField min={0} type="number" onChange={(event) => this.handleUpdate("largura", event)} label="Largura" value={this.state.params.largura} />
                        </div>
                        <div className="ui-g-2">
                            <TZMTextField min={0} type="number" onChange={(event) => this.handleUpdate("altura", event)} label="Altura" value={this.state.params.altura} />
                        </div>
                        <div className="ui-g-2">
                            <TZMTextField min={0} type="number" onChange={(event) => this.handleUpdate("comprimentoRolo", event)} label="Comprimento Rolo" value={this.state.params.comprimentoRolo} />
                        </div>                            
                        <div className="ui-g-2 ui-g-nopad center-button" >                            
                            <Checkbox inputId="produtoAtivo" onChange={(event) => this.handleUpdate("produtoAtivo", {...event, target: {value: event.checked}})} checked={this.state.params.produtoAtivo} />
                            <label htmlFor="produtoAtivo" className="ui-checkbox-label">{`Somente Produtos Ativos`} </label>                            
                        </div>
                        <div className="ui-g-3">
                            <TZMTextField name="produto"  onChange={(event) => this.handleUpdate("produto", event)} label="Produto" placeholder="Descrição, SKU" value={this.state.params.produto} />
                        </div>
                        <div className="ui-g-3">
                            <TZMCombobox name="material" label="Material" onChange={this.handleCombobox} value={this.state.params.material}>
                                {[<option key={"semMaterial"} value={null}>Nenhum registro selecionado...</option>, ...this.checkNullArray(this.state.materiais).map((material) =>
                                    <option key={material.produtoId} value={material.descricao}>{material.descricao}</option>)
                                ]}
                            </TZMCombobox>
                        </div>
                        <div className="ui-g-3">
                            <TZMCombobox name="diametroTubete" label="Diametro Tubete" onChange={this.handleCombobox} value={this.state.params.diametroTubete}>
                                {[<option key={"semDiametro"} value={null}>Nenhum registro selecionado...</option>, ...this.checkNullArray(this.state.diametrosTubete).map((diametroTubete) =>
                                    <option key={diametroTubete.produtoId} value={diametroTubete.descricao}>{diametroTubete.descricao}</option>)
                                ]}
                            </TZMCombobox>
                        </div>
                        <div className="ui-g-3">
                            <TZMCombobox name="sentidoRebobinagem" label="Sentido Rebobinagem" onChange={this.handleCombobox} value={this.state.params.sentidoRebobinagem}>
                                {[<option key={"semRebo"} value={null}>Nenhum registro selecionado...</option>, ...this.checkNullArray(this.state.sentidosRebobinagem).map((sentidoRebobinagem) =>
                                    <option key={sentidoRebobinagem.conteudoAlfa} value={sentidoRebobinagem.descricao}>{sentidoRebobinagem.descricao}</option>)
                                ]}
                            </TZMCombobox>
                        </div>
                    </div>                        
                    <TZMPanelFooter>
                        <TZMButton icon="fas fa-search" label="Procurar" onClick={this.handleList} />
                    </TZMPanelFooter>
                </TZMForm>
            </TZMPanel>
            <TZMPanel>
                <TZMForm>
                    <div className="ui-g">
                        <DataTable  ref={(el) => this.dt = el} emptyMessage="Nenhum registro encontrado" value={this.state.produtos} paginator 
                            rows={15} rowsPerPageOptions={[15, 30, 45]} pageLinkSize={15} onRowClick={this.onRowClick} rowClassName={this.rowClassName}>                
                            <Column sortable={false}  header={ <Checkbox onChange={e => this.selecionarTodos(e.checked)} checked={this.state.selecionarTodos}/>}
                            body={ (rowData, column) => (this.selecionavel(rowData) ? <Checkbox onChange={() => {}} checked={this.isSelecionado(rowData)} /> : '' ) } style={{width: '2em', textAlign: "center" }}/>
                            <Column field="pk.produtoId" key="produtoId" style={{ textAlign: "center", width: "10em" }} header="SKU" />
                            <Column field="descricao" key="descricao" style={{width:"*", textAlign: "left" }} header="Produto" />
                            <Column key="saldoDisponivel"  field="saldoDisponivel"  style={{ width: "9.5em" }} header="Saldo Disponível"  body={(rowData) => {
                                return <div style={{ textAlign: "right" }}>{toDecimal(rowData["saldoDisponivel"])}</div>;
                            }} />
                            <Column field="unidade" key="unidade" style={{ textAlign: "center", width: "4.5em" }} header="Unidade" />
                            <Column field="nomeConhecido" key="nomeConhecido" style={{ textAlign: "center", width: "13em" }} header="Tradução" />
                            <Column field="posicao" key="posicao" style={{ textAlign: "center", width: "8em" }} header="Posição" />
                        </DataTable>
                    </div> 
                </TZMForm>
            </TZMPanel>
            <TZMPanelFooter>
                <TZMButton className="ui-button-primary" label="Adicionar" icon="fas fa-plus" onClick={this.adicionarSelecionados} />
				<TZMButton className="ui-button-secondary" label="Fechar" icon="fas fa-close" onClick={this.onHide} />                
			</TZMPanelFooter>            
        </TZMDialog>
        );
    }
}
