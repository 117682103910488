import moment from "moment";
import { Column } from 'primereact/components/column/Column';
import { ColumnGroup } from 'primereact/components/columngroup/ColumnGroup';
import { Growl } from 'primereact/components/growl/Growl';
import { MultiSelect } from 'primereact/components/multiselect/MultiSelect';
import { Panel } from "primereact/components/panel/Panel";
import { Row } from 'primereact/components/row/Row';
import React from "react";
import ReactTooltip from 'react-tooltip';
import TZMAutoComplete from "../../../components/common/TZMAutoComplete";
import TZMButton from "../../../components/common/TZMButton";
import TZMCalendar from "../../../components/common/TZMCalendar";
import TZMCombobox from "../../../components/common/TZMCombobox";
import TZMForm from "../../../components/common/TZMForm";
import { toCurrency, toDate, toDecimal, toPercent } from "../../../components/common/TZMFormatter";
import TZMTextField from "../../../components/common/TZMTextField";
import TZMMainContent from "../../../components/main/TZMMainContent";
import { ModalRealm } from "../../../components/modal/ModalRealm";
import TZMPanel from "../../../components/panel/TZMPanel";
import TZMPanelFooter from "../../../components/panel/TZMPanelFooter"; 
import TZMColumn from "../../../components/table/TZMColumn";
import TZMTable from "../../../components/table/TZMTable";
import { Fetch } from "../../../utilities/Fetch";
import Templates from "../../../utilities/Templates";
import TZMShortcut from "../../../utilities/TZMShortcut";
import TZMUserData from "../../../utilities/TZMUserData";
import { UF } from "../../../utilities/UF";
import EditarPedido from "../../rfid/pedido/EditarPedido";
import UsuarioTemplate from "../../comum/usuario/UsuarioTemplate";
import { VwClienteService } from "../../servicos/VwClienteService";
import { CondicaoPagamentoService } from "../../servicos/CondicaoPagamentoService";
import { IntegracaoService } from "../../servicos/IntegracaoService";
import { RegionalService } from "../../servicos/RegionalService";
import { RelatorioService } from "../../servicos/RelatorioService";
import { UsuarioService } from "../../servicos/UsuarioService";
import { VendedorService } from "../../servicos/VendedorService";
import { VwPedidoService } from "../../servicos/VwPedidoService";
import { VwProdutoService } from "../../servicos/VwProdutoService";
import PrevisaoLancamentoRFID from "./PrevisaoLancamento";
import { MotivoBloqueio } from "../../comum/pedido/MotivoBloqueio";

const title = "Pedidos";
const empresaNome = process.env.REACT_APP_EMPRESA_NOME;

export default class Pedido extends React.Component {

    constructor() {
        super();
        this.state = {
            list: [],
            status: [],
            data: {},
            params: this.defaultParams,
            selectedPedido: "",
            expandedRows: [],
            fixedSeller: false,
			fixedGerente: false,
			fixedSupervisor: false,
			isLoading: false,
            producao: false,
            filtrarGrupo: false,
			regional: {
				regional: null,
				vendedor: null, 
				gerente: null,
				supervisor: null,
			}
        };
		this.usuarioService = new UsuarioService();
		this.vwClienteService = new VwClienteService();
		this.vwPedidoService = new VwPedidoService();		
		this.vwProdutoService = new VwProdutoService();
		this.vendedorService = new VendedorService();
		this.integracaoService = new IntegracaoService();		
		this.condicaoPagamentoService = new CondicaoPagamentoService();
		this.relatorioService = new RelatorioService();
		this.regionalService = new RegionalService();
    }


    defaultParams = {
        pedido: "",
        notaFiscal: "",
        inclusao: this.rangeDiario(),
        entrega: [null, null],
        faturamento: [null, null],
        status: "",
        painel: "true",
        pronto: "false",
        produto: { pk: {}, descricao: "" },
        cliente: { pk: {}, descricaoAmigavel: "" },       
        uf: "",
        page: 0,
        size: 10000,
        sort: undefined,
        cancelado: false
    };

    pedidoConfirmado = (pedidoId) => {
        this.growl.show({ closable: true, severity: 'success', sticky: true, summary: 'Pedido Confirmado', detail: `Pedido ${pedidoId} aguardando processamento!` });
    }

    pedidoSalvo = (pedidoId) => {
        this.growl.show({ closable: true, severity: 'info', sticky: true, summary: 'Pedido Salvo', detail: `Pedido ${pedidoId} salvo como rascunho!` });
    }
    displayGrowl = (pedido) => {
        if (pedido.status === 'CONFIRMADO') {
            this.pedidoConfirmado(pedido.pedidoId);
        } else if (pedido.status === 'RASCUNHO') {
            this.pedidoSalvo(pedido.pedidoId);
        }
    }

    componentDidMount() {		
		if (TZMUserData.isAutenticado()) {
			Fetch.Get("/ping");			
            let regional = this.state.regional;
            let usuario = TZMUserData.getPrincipal();
			switch (usuario.nivel) {
				case TZMUserData.REPRESENTANTE:
					if (usuario.vendedor) {
						regional.vendedor = usuario.vendedor;
						this.setState({ regional, fixedSeller: true });
					}
					break;
				case TZMUserData.GERENTE:										
					regional.gerente = usuario;										
					this.setState({ regional, fixedGerente: true });
				break;
				case TZMUserData.SUPERVISOR:
					regional.supervisor = usuario;
					this.setState({ regional, fixedSupervisor: true });
				break;			
			default:
				break;
			}
		} 
	}

    rangeDiario() {
        let date = new Date();
        let firstDay = moment(date).format("YYYY-MM-DD");
        return [firstDay, firstDay];
    }

    rangeMensal(startMonth = 0, endMonth = 1) {
        let date = new Date();
        let firstDay = moment(new Date(date.getFullYear(), date.getMonth() + startMonth, 1)).format("YYYY-MM-DD");
        if (endMonth == null) {
            return [firstDay, null];
        }
        return [firstDay, moment(new Date(date.getFullYear(), date.getMonth() + endMonth, 0)).format("YYYY-MM-DD")];
    }

    dateFormat(rowData, column) {
        let campo = rowData[column.field];
        if (campo !== null) {
            return toDate(campo);
        }
        return null;
    }

    currencyFormat(rowData, column) {
        return <div style={{ textAlign: "right" }}>{toCurrency(rowData[column.field])}</div>;
    }

    decimalFormat = (rowData, column) => {
        return <div style={{ textAlign: "right" }}>{toDecimal(rowData[column.field])}</div>;
    }

    percentFormat = (rowData, column) => {
        return <div style={{ textAlign: "right" }}>{toPercent(rowData[column.field])}</div>;
    }
    
    espelhoPedido = async (pedido) => {
        const list = this.state.list;
        list.filter(p => p.pk.pedidoId === pedido.pk.pedidoId).forEach(p => {
            p._isLoading = true;
        });
        this.setState({ list });
        await this.relatorioService.espelhoPedido(pedido.pk, pedido.pk.pedidoId).then(response => {
            const list = this.state.list;
            list.filter(p => p.pk.pedidoId === pedido.pk.pedidoId).forEach(p => p._isLoading = false);
            this.setState({ list });
        });
    }

    columns = [
        <Column key={"expander"} style={{ width: "2em" }} expander />,
        <Column sortable field="pk.pedidoId" key="pk.pedidoId" style={{ width: "6em", textAlign: "right" }} header="Pedido" />,
        <Column sortable field="pedidoCliente" key="pedidoCliente" style={{ width: "9em" }} header="Pedido/Cliente" />,
        <Column sortable field="cliente.razaoSocial" key="cliente.razaoSocial" style={{ width: "*" }} header="Cliente" />,
        <Column sortable field="dataInclusao" key="dataInclusao" style={{ width: "6.3em" }} body={this.dateFormat} header="Inclusão" />,
        <Column sortable field="dataEntrega" key="dataEntrega" style={{ width: "6.3em" }} body={this.dateFormat} header="Entrega" />,
        <Column sortable field="totalM2" key="totalM2" style={{ width: "7em", textAlign: "right" }} header="Total em mi" body={(rowData) => toDecimal(Math.round(rowData.totalM2))} />,
        <Column sortable field="valorTotal" style={{ width: "9em" }} key="valorTotal" header="Valor" body={this.currencyFormat} />,
        <Column key={"status"} style={{ width: "4em" }} header="" body={this.columnStatus} />,
        <Column style={{ width: "7.5em" }} key="acoes" header="Ações" body={(pedido) => {
            let botoes = [];
            if (pedido.sistema === 'OMEGA') {
                botoes.push(<span key={0} title="Visualizar" className="fa fa-desktop ui-tzm-icon" onClick={() => this.selectPedido(pedido)} />);
                botoes.push(<span key={1} title="Espelho Pedido" className={pedido._isLoading ? `fa fa-spin fa-circle-notch` : `fa fa-file-pdf ui-tzm-icon`} onClick={() => this.espelhoPedido(pedido)} />);
            } else {
                botoes.push(<span key={0} title="Editar" className="fa fa-edit ui-tzm-icon" onClick={() => this.selectPedido(pedido)} />);
            }

            // botoes.push(<span key={2} title="Copiar" style={{ margin: "0 3px 0 2px" }} className="fa fa-copy ui-tzm-icon" onClick={() => this.clonarPedido(pedido)} />);

            if (pedido.id != null && (pedido.status === 'CONFIRMADO' || pedido.status === 'ERRO' || pedido.status === "NAO_PROCESSADO")) {
                botoes.push(<span key={3} title="Visualizar Registros de Integração" className="fa fa-database ui-tzm-icon" onClick={(event) => this.showLogsIntegracao(pedido)} />);
            }

            if (pedido.id != null && (pedido.status === 'ERRO' || pedido.status === 'RASCUNHO')) {
                botoes.push(<span key={4} title="Cancelar" className="fa fa-ban ui-tzm-icon" onClick={(event) => this.cancelarPedido(pedido)} />);
            }
            if (pedido.status === "BLOQUEADO") {
                botoes.push(<span key={5} className={`fas fa-shield-alt ui-tzm-icon`} title="Motivos de Bloqueio" onClick={() => this.motivoBloqueio(pedido)} />)
            }
            if (pedido.sistema === 'OMEGA' && pedido.notasGeradas > 0) {
				let texto = pedido.notasGeradas > 1 ? `Notas fiscais disponíveis para download.` : `Nota fiscal disponível para download.`;
				botoes.push(<span key={7} className={pedido._isLoadingNF ? `fa fa-spin fa-circle-notch` : `badge-notas  ui-tzm-icon`} onClick={() => this.notasFiscais(pedido)} title={texto}>
					{pedido._isLoadingNF ? null : pedido.notasGeradas}
				</span>);
			}
            return <div style={{ textAlign: "center" }}>{botoes}</div>;
        }} />
    ];

	chipFormat = (rowData) => {
		return (
			<div>
				{rowData.movimentos ? rowData.movimentos.filter((movimento) => movimento.movimento === "FAT").map((movimento) => {
					return (
						<div key={TZMUserData.getIdGenerator()} style={{
							backgroundColor: "#607D8B", padding: "2px 10px 2px 10px", borderRadius: "16px", margin: "2px", fontSize: "12px", color: "#FFF", display: "inline-block"
						}} >
						<span title="Nota Fiscal" style={{cursor: "pointer"}} className={movimento._isLoadingNF ? `fa fa-spin fa-circle-notch` : null} 
							onClick={() => this.notaFiscal(movimento.notaFiscalId, movimento.pk.pedidoId)} >
							{`${movimento.notaFiscalId}  (${moment(movimento.pk.dataEmissao).format("DD/MM/YYYY")})`}
						</span>
						
						</div>
					);
				}) : null}
			</div>
		);
    }
    
	notasFiscais = async (pedido) => {
		const list = this.state.list;
		list.filter(p => p.pk.pedidoId === pedido.pk.pedidoId).forEach(p => {
			p._isLoadingNF = true;
		});
		this.setState({ list });
		await this.relatorioService.notasFiscais(pedido.pk.pedidoId).then(response => {
			const list = this.state.list;
			list.filter(p => p.pk.pedidoId === pedido.pk.pedidoId).forEach(p => p._isLoadingNF = false);
			this.setState({ list });
		});
	}

	notaFiscal = async (notaFiscalId, pedidoId) => {		
		await this.relatorioService.notaFiscal(notaFiscalId,pedidoId).then(response => {});
	}

    columnStatus(rowData) {
        let value, color, title;
        if (rowData.pronto !== undefined && rowData.pronto != null) {
            if (rowData.pronto) {
                value = "pallet";
                color = "#4CAF50";
                title = "Pronto";
            } else {
                switch (rowData.status) {
                    case "CARTEIRA":
                        value = "dolly-flatbed";
                        color = "#607D8B";
                        title = "Produzir";
                        break;
                    case "BLOQUEADO":
                        value = "dolly-flatbed";
                        color = "#607D8B";
                        title = "Produzir";
                        break;
                    case "FATURADO":
                        value = "pallet";
                        color = "#4CAF50";
                        title = "Pronto";
                        break;
                    default:
                        break;
                }
            }
        }        
		return <div className='ui-g' ><div className='ui-g-12' style={{ textAlign: "center" }}>
		{IntegracaoService.columnStatus(rowData)}
		<span key={1} className={`fas fa-${value}`} title={title} style={{ color }} />&nbsp;            		
		{rowData.regionalDescricaoExcecao != null ? <span className="fake-link fas fa-globe" data-tip={rowData.regionalDescricaoExcecao} data-for="excecao" data-multiline={true} />  : null}           
		<ReactTooltip id="excecao" place="top" type="info" effect="float"/>         
	    </div></div>;
    }

    headers = this.columns.slice(1).map((column) => { return { key: column.props.field, label: column.props.header }; });

    handleList = () => {
        let params = Object.assign({}, this.state.params);
        let regional = this.state.regional;
        Object.keys(params).forEach(k => params[k] != null && typeof params[k] === "string" ? params[k] = params[k].trim() : null);        
        params.painel = "false";
        params.filtrarGrupo = this.state.filtrarGrupo ? "true" : "false";
        params.empresa = TZMUserData.getEmpresaRFID();
        
        if (params.produto) {
            if (params.produto.pk.produtoId) {
                params.produto = params.produto.pk;
            } else {
                params.produto = null;
            }
        }
        if (params.cliente) {
            if (params.cliente.pk.clienteId) {
                params.cliente = params.cliente.pk;
            } else {
                params.cliente = null;
            }
        }
        if (this.state.producao) {
            params.pronto = "true";
        } else {
            params.pronto = "false";
        }

        if (regional.vendedor && regional.vendedor.id){
			params.vendedor = regional.vendedor.vendedorId.vendedorId;
		}

		if (regional.regional) {
			params.regional = regional.regional.id; 
		}

		if (regional.gerente){
			params.gerente = regional.gerente.id;
		}

		if (regional.supervisor){
			params.supervisor = regional.supervisor.id;			
        }
        
        params.status = this.state.status;
        this.handleViewList(params, []);
        
    }

    handleViewList = (params, pedidos) => {
        this.vwPedidoService.listarSimples(params).then((vwPedidos) => {
            pedidos = pedidos.concat(vwPedidos);
            if (this.state.detalhados) {
                pedidos.forEach((item) => {
                    this.vwPedidoService.detalhar(item.pk).then((response) => {
                        item.itens = response;
                        let expandedRows = this.state.expandedRows;
                        if (!expandedRows) expandedRows = [];
                        expandedRows.push(item);
                        this.setState({ expandedRows });
                    });
                });
            }      
            this.setState({ list: pedidos });
        });
    }

    handleUpdate = (key, event) => {
        this.setState({ params: { ...this.state.params, [key]: event.target.value } });
    }

    rowExpansionTemplate = (data) => {
        let footer = (
            <ColumnGroup>
                <Row>
                    <Column colSpan={10} style={{ fontSize: "12px", textAlign: "right" }} footer="Total dos itens" />
                    <Column style={{ textAlign: "right" }} footer={toCurrency(data.itens.map((item) => item.valorTotalImposto).reduce((a, b) => a + b, 0))} />
                </Row>
            </ColumnGroup>
        );
        return (
            <TZMTable value={data.itens} footerColumnGroup={footer}>
                <TZMColumn key="codigoProduto" style={{ textAlign: "center", width: "10em" }} field="produto.pk.produtoId" header="Cod. Produto" />
                <TZMColumn key="descricaoProduto" style={{ width: "*" }} field="produto.descricao" header="Produto" />
                <TZMColumn key="quantidadeM2" style={{ width: "6em" }} field="quantidadeM2" header="Qtde em mi" body={(rowData) => <div style={{ textAlign: "right" }}>{toDecimal(rowData.quantidadeM2)}</div>} />
                <TZMColumn key="quantidade" style={{ width: "6em" }} field="quantidade" header="Quantidade" body={(rowData) => <div style={{ textAlign: "right" }}>{rowData.quantidade}</div>} />
                <TZMColumn key="quantidadeFaturada" style={{ width: "6em" }} field="quantidadeFaturada" header="Qtde Faturada" body={(rowData) => <div style={{ textAlign: "right" }}>{rowData.quantidadeFaturada}</div>} />
                <TZMColumn key="unidade" field="unidade" style={{ textAlign: "center", width: "4em" }} header="Unidade" />
                <TZMColumn key="valorUnitario" style={{ width: "7em" }} field="valorUnitario" header="Valor Unitário" body={this.currencyFormat} />
                <TZMColumn key="percentualIcms" style={{ width: "4em" }} field="percentualIcms" header="ICMS %" body={this.percentFormat} />
                <TZMColumn key="notaFiscal" style={{ width: "*", textAlign: "center" }} body={this.chipFormat} header="Nota Fiscal / Data Emissão" />
                <TZMColumn key="valorTotalItem" style={{ width: "8em" }} field="valorTotalImposto" header="Valor Total" body={this.currencyFormat} />
                <TZMColumn key="status" field="status" style={{ textAlign: "center", width: "8em" }} header="Status" />
            </TZMTable>
        );
    }

    rowExpand = (event) => {
        if (this.state.expandedRows != null && this.state.expandedRows.length > event.data.length) {
            this.setState({ expandedRows: event.data });
        } else {
            if (event.data.length > 0) {
                let item = event.data.pop();
                this.vwPedidoService.detalhar(item.pk).then((response) => {
                    item.itens = response;
                    this.setState({ expandedRows: [...event.data, item] });
                });
            } else {
                this.setState({ expandedRows: event.data });
            }
        }
    }

    selectPedido = (pedido) => {
        if (pedido != null) {
            this.vwPedidoService.completar(pedido.pk).then((response) => {
                ModalRealm.showDialog(<EditarPedido key={TZMUserData.getIdGenerator()} pedido={response} onSave={() => {
                    this.handleList();
                    this.displayGrowl(pedido);
                }} />);
            });
        }
    }

    motivoBloqueio = (rowData) => {
        ModalRealm.showDialog(<MotivoBloqueio key={TZMUserData.getIdGenerator()} logs={rowData.motivosBloqueio} />);
    }

    toggleHidden = () => {
        this.setState({ params: { ...this.state.params, desativados: !this.state.params.desativados } });
    }
    
	withParams = (additionalParams) => {
		let regional = this.state.regional;
		let params = Object.assign({}, this.defaultParams, additionalParams);

		if (regional.vendedor && regional.vendedor.id){
			params.vendedor = regional.vendedor.vendedorId.vendedorId;
		}
		if (regional.gerente){
			params.gerente = regional.gerente.id;
		}
		if (regional.supervisor){
			params.supervisor = regional.supervisor.id;			
        }	
        if (regional.regional) {
			params.regional = regional.regional.id; 
		}
	
		this.handleViewList(params, []);
	}

    toggleDetailed = () => {
        this.setState({ detalhados: !this.state.detalhados });
    }

    toggleProducao = () => {
        this.setState({ producao: !this.state.producao });
        setTimeout(() => {
            this.handleList();
        }, 50);
    }

    totalGeralM2 = () => {
        let params = {};
		let regional = this.state.regional;
		if (regional.vendedor && regional.vendedor.id){
			params.vendedor = regional.vendedor.vendedorId.vendedorId;
		}
		if (regional.gerente){
			params.gerente = regional.gerente.id;
		}
		if (regional.supervisor){
			params.supervisor = regional.supervisor.id;			
        }
        if (regional.regional) {
			params.regional = regional.regional.id; 
		}
        
        let params1 = Object.assign({ params, empresa: TZMUserData.getEmpresaRFID(), cliente: null, produto: null, painel: "true", pronto: this.state.producao ? "true" : "false", status: "FATURADO", faturamento: this.rangeMensal(), inclusao: [null, null], entrega: [null, null] });
        let params2 = Object.assign({ params, empresa: TZMUserData.getEmpresaRFID(), cliente: null, produto: null, painel: "true", pronto: this.state.producao ? "true" : "false", status: "CARTEIRA", entrega: this.rangeMensal(), inclusao: [null, null] });
        let params3 = Object.assign({ params, empresa: TZMUserData.getEmpresaRFID(), cliente: null, produto: null, painel: "true", pronto: this.state.producao ? "true" : "false", status: "BLOQUEADO", entrega: this.rangeMensal(), inclusao: [null, null] });
        this.vwPedidoService.listarSimples(params1).then((response1) => {
            this.vwPedidoService.listarSimples(params2).then((response2) => {
                this.vwPedidoService.listarSimples(params3).then((response3) => {
                    let list = response1.concat(response2).concat(response3);
                    this.setState({ list });
                });
            });
        });
    }   

    listProdutos = (event) => {
        this.vwProdutoService.autoCompletar(event.query).then((list) => {
            this.setState({ listProdutos: list });
        });
    }

    listarGerentes = (event) => {
		this.usuarioService.autoCompletar({usuario: event.query,regional: TZMUserData.GERENTE,sort:'nome'}).then((gerentes) => this.setState({gerentes}));
    }
    

	listarSupervisores = (event) => {		
		let supervisores = [];
		let usuario = TZMUserData.getPrincipal();		
		switch (usuario.nivel) {
			case TZMUserData.GERENTE:
				supervisores = usuario.regionais.filter((regional) => regional.supervisor && regional.supervisor.nome.toLowerCase().includes(event.query.toLowerCase()));
				supervisores = supervisores.map(r => r.supervisor);
				supervisores = supervisores.filter((v, i, a) => a.findIndex(w => w.id === v.id) === i);				
				this.setState({supervisores})	
			break;			
		default:			
			this.usuarioService.autoCompletar({usuario: event.query,regional: TZMUserData.SUPERVISOR, sort:'nome'}).then((supervisores) => this.setState({supervisores}));
			break;
		}
	}

	listarVendedores = async (event) => { 
		let usuario = TZMUserData.getPrincipal();
		let vendedores = [];
		switch (usuario.nivel) {
			case TZMUserData.REPRESENTANTE:
					this.setState({vendedores});				
				break;
			case TZMUserData.GERENTE:
                usuario.regionais.forEach((regional) => {
                    vendedores = vendedores.concat(regional.vendedores.filter((vendedor) => 
                    vendedor.nome.toLowerCase().includes(event.query.toLowerCase()) ||  
                    vendedor.descricaoAmigavel.toLowerCase().includes(event.query.toLowerCase())));
                });
            vendedores = vendedores.filter((v, i, a) => a.findIndex(w => w.id === v.id) === i);
				this.setState({vendedores})
			break;
			case TZMUserData.SUPERVISOR:
				if (usuario.regionais) {
					usuario.regionais.forEach((regional) => {
						vendedores = vendedores.concat(regional.vendedores.filter((vendedor) => 
						vendedor.nome.toLowerCase().includes(event.query.toLowerCase()) ||  
						vendedor.descricaoAmigavel.toLowerCase().includes(event.query.toLowerCase())));
					});
					vendedores = vendedores.filter((v, i, a) => a.findIndex(w => w.id === v.id) === i);
					this.setState({vendedores})				
				} else {
					this.setState({vendedores});
				}	
			break;			
		default:
			await this.vendedorService.autoCompletar({vendedor: event.query, sort:'nome'}).then((vendedores) => this.setState({vendedores}));
			break;
		}
	}	

	listarRegionais = async (event) => {
		let regionais = [];	
		let usuario = TZMUserData.getPrincipal();	
		switch (usuario.nivel) {
			case TZMUserData.REPRESENTANTE:
				if (usuario.vendedor) {					
				}
				break;
			case TZMUserData.GERENTE:	
				regionais = usuario.regionais.filter((regional) => regional.descricao.toLowerCase().includes(event.query.toLowerCase()));				
				this.setState({regionais})
			break;
			case TZMUserData.SUPERVISOR:
				if (usuario.regionais) {
					regionais = usuario.regionais.filter((regional) => regional.descricao.toLowerCase().includes(event.query.toLowerCase()));
					this.setState({regionais})				
				} else {
					this.setState({regionais});
				}	
			break;			
		default:
			await this.regionalService.autoCompletar({descricao: event.query, sort: "descricao"}).then((regionais) => this.setState({regionais}));
			break;
		}
	}

	listClientes = async (event) => {
        await this.vwClienteService.autoComplete({cliente: event.query, sort: 'razao'}).then((listClientes) => this.setState({listClientes}));
    }

    produtoTemplate = (value) => {
        return (
            <div className="ui-tzm-suggestion-line"><div className="ui-tzm-suggestion-chip">{value.pk.produtoId}</div>{value.descricao}</div>
        );
    }

    clienteTemplate(cliente) {
        return <div><i className={`fa fa-${cliente.posicao === "ATIVO" ? "check-circle" : "minus-circle"}`} /> {cliente.pk.clienteId} - {cliente.razaoSocial}</div>;
    }

    queryAutoComplete = (event) => {
        let params = this.state.params;
        params[event.name][event.field] = event.value;
        this.setState({ params });
    }

    selectAutoComplete = (event) => {
        let params = this.state.params;
        params[event.name] = event.value;
        this.setState({ params });
    }

    clearAutoComplete = (event) => {
        let params = this.state.params;
        params[event.name] = { pk: {}, [event.field]: "" };
        this.setState({ params });
    }

    handleUpdateParams = (event, field, index) => {
        let params = this.state.params;
        params[field][index] = event.target.value;
        this.setState({ params });
    }

    handleClear = (event) => {
		let regional = this.state.regional;
		regional[event.name] = null;
		this.setState({regional});
	}

	handleSelect = (event) => {
		let regional = this.state.regional;
		regional[event.name] = event.value;
		this.setState({regional});
	}

	handleUnselect = (event) => {
		let regional = this.state.regional;
		if (!regional[event.name]) {
			regional[event.name] = [];
		}
		regional[event.name] = regional[event.name].filter((e) => e.id !== event.value.id);
		this.setState({regional});
	}

	renderSuggestion = () => {	
		let usuario = TZMUserData.getPrincipal();								
		switch (usuario.nivel) {		
			case TZMUserData.REPRESENTANTE:
				break;
			case TZMUserData.GERENTE:
				return (<div className="ui-g-12 ui-g-nopad">
						<div className="ui-g-4 ui-fluid">
							<TZMAutoComplete  onClear={this.handleClear} onSelect={this.handleSelect} onChange={this.handleSelect}
								suggestions={this.state.regionais} dropdown completeMethod={this.listarRegionais} name="regional" field="descricao" 
								label="Regional" onUnselect={this.handleUnselect} placeholder="Descrição" value={this.state.regional.regional} />
						</div>
						<div className="ui-g-4 ui-fluid">							
							<TZMAutoComplete itemTemplate={UsuarioTemplate.usuarioTemplate} onClear={this.handleClear} onSelect={this.handleSelect} onChange={this.handleSelect}
								suggestions={this.state.gerentes} dropdown completeMethod={this.listarGerentes} name="gerente" field="descricaoAmigavel" 
								disabled={this.state.fixedGerente} label="Gerente" placeholder="E-mail, Login ou Nome" onUnselect={this.handleUnselect} value={this.state.regional.gerente} />
							
						</div>
						<div className="ui-g-4 ui-fluid">
							<TZMAutoComplete itemTemplate={UsuarioTemplate.usuarioTemplate} onClear={this.handleClear} onSelect={this.handleSelect} onChange={this.handleSelect}
								suggestions={this.state.supervisores} dropdown completeMethod={this.listarSupervisores} name="supervisor" field="descricaoAmigavel" 
								disabled={this.state.fixedSupervisor} label="Supervisor" placeholder="E-mail, Login ou Nome" onUnselect={this.handleUnselect} value={this.state.regional.supervisor} />
						</div>
					</div>
				);				
			case TZMUserData.SUPERVISOR:				
				return (
					<div className="ui-g-12 ui-g-nopad">
						<div className="ui-g-6 ui-fluid">
							<TZMAutoComplete  onClear={this.handleClear} onSelect={this.handleSelect} onChange={this.handleSelect}
								suggestions={this.state.regionais} dropdown completeMethod={this.listarRegionais} name="regional" field="descricao" 
								label="Regional" onUnselect={this.handleUnselect} placeholder="Descrição" value={this.state.regional.regional} />
						</div>

						<div className="ui-g-6 ui-fluid">
							<TZMAutoComplete itemTemplate={UsuarioTemplate.usuarioTemplate} onClear={this.handleClear} onSelect={this.handleSelect} onChange={this.handleSelect}
								suggestions={this.state.supervisores} dropdown completeMethod={this.listarSupervisores} name="supervisor" field="descricaoAmigavel" 
								disabled={this.state.fixedSupervisor} label="Supervisor" placeholder="E-mail, Login ou Nome" onUnselect={this.handleUnselect} value={this.state.regional.supervisor} />
						</div>	
					</div>
				);				
		default:			
			return (
				<div className="ui-g-12 ui-g-nopad">
					<div className="ui-g-4 ui-fluid">
						<TZMAutoComplete  onClear={this.handleClear} onSelect={this.handleSelect} onChange={this.handleSelect}
							suggestions={this.state.regionais} dropdown completeMethod={this.listarRegionais} name="regional" field="descricao" 
							label="Regional" onUnselect={this.handleUnselect} placeholder="Descrição" value={this.state.regional.regional} />
					</div>
					<div className="ui-g-4 ui-fluid">							
						<TZMAutoComplete itemTemplate={UsuarioTemplate.usuarioTemplate} onClear={this.handleClear} onSelect={this.handleSelect} onChange={this.handleSelect}
							suggestions={this.state.gerentes} dropdown completeMethod={this.listarGerentes} name="gerente" field="descricaoAmigavel" 
							disabled={this.state.fixedGerente} label="Gerente" placeholder="E-mail, Login ou Nome" onUnselect={this.handleUnselect} value={this.state.regional.gerente} />
						
					</div>
					<div className="ui-g-4 ui-fluid">
						<TZMAutoComplete itemTemplate={UsuarioTemplate.usuarioTemplate} onClear={this.handleClear} onSelect={this.handleSelect} onChange={this.handleSelect}
							suggestions={this.state.supervisores} dropdown completeMethod={this.listarSupervisores} name="supervisor" field="descricaoAmigavel" 
							disabled={this.state.fixedSupervisor} label="Supervisor" placeholder="E-mail, Login ou Nome" onUnselect={this.handleUnselect} value={this.state.regional.supervisor} />
					</div>
				</div>
			);			
		}		
	}


    render() {
        const optionsStatus = [            
            {label: 'Carteira', value: 'CARTEIRA'},
            {label: 'Bloqueado', value: 'BLOQUEADO'},
            {label: 'Faturado', value: 'FATURADO'}
        ];
        return (
            <TZMMainContent>
                <div className="ui-g">
                    <div className="ui-g-9">
                        <TZMPanel header={title}>
                            <TZMForm>
                                <div className="ui-g">
                                    <div className="ui-g-2">
                                        <TZMCalendar label="Período de Inclusão" placeholder="Inicial" onChange={(event) => this.handleUpdateParams(event, "inclusao", 0)} value={this.state.params.inclusao[0]} />
                                    </div>
                                    <div className="ui-g-2">
                                        <TZMCalendar label="&nbsp;" placeholder="Final" onChange={(event) => this.handleUpdateParams(event, "inclusao", 1)} value={this.state.params.inclusao[1]} />
                                    </div>
                                    <div className="ui-g-2">
                                        <TZMCalendar label="Período de Entrega" placeholder="Inicial" onChange={(event) => this.handleUpdateParams(event, "entrega", 0)} value={this.state.params.entrega[0]} />
                                    </div>
                                    <div className="ui-g-2">
                                        <TZMCalendar label="&nbsp;" placeholder="Final" onChange={(event) => this.handleUpdateParams(event, "entrega", 1)} value={this.state.params.entrega[1]} />
                                    </div>
                                    <div className="ui-g-2">
                                        <TZMCalendar label="Período de Faturamento" placeholder="Inicial" onChange={(event) => this.handleUpdateParams(event, "faturamento", 0)} value={this.state.params.faturamento[0]} />
                                    </div>
                                    <div className="ui-g-2">
                                        <TZMCalendar label="&nbsp;" placeholder="Final" onChange={(event) => this.handleUpdateParams(event, "faturamento", 1)} value={this.state.params.faturamento[1]} />
                                    </div>
                                    <div className="ui-g-3">
                                        <TZMTextField type="number" autoFocus placeholder="Número" label="Pedido" onChange={(event) => this.handleUpdate("pedido", event)} value={this.state.params.pedido} />
                                    </div>
                                    <div className="ui-g-6">
                                        <TZMAutoComplete itemTemplate={this.clienteTemplate} onClear={this.clearAutoComplete} name="cliente"
                                            field="descricaoAmigavel" onSelect={this.selectAutoComplete} suggestions={this.state.listClientes}
                                            completeMethod={this.listClientes} placeholder="Razão social, fantasia ou CNPJ" onChange={this.queryAutoComplete}
                                            value={this.state.params.cliente.descricaoAmigavel} label="Cliente" />
                                    </div>
                                    <div className="ui-g-1">
                                        <TZMCombobox value={this.state.params.uf} label="UF" onChange={(event) => this.handleUpdate("uf", event)}>{UF.list}</TZMCombobox>
                                    </div>
                                    <div className="ui-g-2">
                                        <TZMTextField type="number" autoFocus placeholder="Número da NF" label="Nota Fiscal" onChange={(event) => this.handleUpdate("notaFiscal", event)} value={this.state.params.notaFiscal} />
                                    </div>
									<div className="ui-g-4">
										<span className="ui-fluid ui-g-nopad">
											<TZMAutoComplete itemTemplate={Templates.vendedorTemplateOmega} onClear={this.handleClear} onSelect={this.handleSelect} onChange={this.handleSelect}
												suggestions={this.state.vendedores} dropdown completeMethod={this.listarVendedores} name="vendedor" field="descricaoAmigavel" 
												disabled={this.state.fixedSeller} label="Vendedor" placeholder="Código Omega, E-mail ou Nome" onUnselect={this.handleUnselect} value={this.state.regional.vendedor} />
										</span>	
									</div>
                                    <div className="ui-g-2">
                                        <TZMTextField disabled forac="true" value={this.state.params.produto.pk.produtoId} label="Produto" />
                                    </div>
                                    <div className="ui-g-4">
                                        <TZMAutoComplete onClear={this.clearAutoComplete} itemTemplate={this.produtoTemplate} onSelect={this.selectAutoComplete} suggestions={this.state.listProdutos} completeMethod={this.listProdutos} name="produto" field="descricao" placeholder="Código ou descrição" onChange={this.queryAutoComplete} value={this.state.params.produto.descricao} label="&nbsp;" />
                                    </div>
                                    <div className="ui-g-2 ui-textfield-group">
                                        <label className="ui-input-label">Status</label><br />
                                        <MultiSelect value={this.state.status} defaultLabel="Selecione" options={optionsStatus} onChange={(e) => this.setState({status: e.value})} style={{minWidth:'13em'}} />                                    
                                    </div>
                                    {this.renderSuggestion()}
                                </div>
                                <TZMPanelFooter>
                                    <TZMButton title={this.state.detalhados ? "Expandir Pedidos" : "Exibir Resumo"} style={{ float: "left" }} onClick={this.toggleDetailed} secondary="true" icon={this.state.detalhados ? "fas fa-folder-open" : "fas fa-folder"} />

                                    <TZMButton id="warehouse" title={this.state.producao ? "Ocultar status Produzir/Pronto" : "Mostrar status Produzir/Pronto"}
                                        style={{ float: "left", color: this.state.producao ? '#4CAF50' : '#607D8B' }} onClick={this.toggleProducao} secondary="true"
                                        icon={this.state.producao ? "fas fa-pallet" : "fas fa-warehouse "} />                                    
                                    <TZMButton icon="fas fa-search" label="Procurar" onClick={this.handleList} />
                                </TZMPanelFooter>
                            </TZMForm>
                        </TZMPanel>
                        <div style={{ height: "8px" }} />
                        <TZMPanel header={<div>
                            <div className="ui-g-6" style={{ textAlign: "left" }}> Resultado da Pesquisa</div>
                            <div className="ui-g-2" style={{ textAlign: "right" }}>Total dos pedidos</div>
                            <div className="ui-g-2" style={{ textAlign: "right" }}>{toDecimal(Math.round(this.state.list.map((item) => item.totalM2).reduce((a, b) => a + b, 0)))} mi</div>
                            <div className="ui-g-2" style={{ textAlign: "right" }}>{toCurrency(Math.round(this.state.list.map((item) => item.valorTotal).reduce((a, b) => a + b, 0)))}</div>
                        </div>} >
                            <TZMTable ref={(el) => this.dt = el} onRowToggle={this.rowExpand} rowExpansionTemplate={this.rowExpansionTemplate} expandedRows={this.state.expandedRows} value={this.state.list}
                                paginator rows={15} rowsPerPageOptions={[15, 30, 45]} pageLinkSize={15}
                                footerColumnGroup={
                                    <ColumnGroup>
                                        <Row>
                                            <Column colSpan={2} style={{ fontSize: "12px", textAlign: "right" }} footer="Total de registros " />
                                            <Column style={{ textAlign: "right" }} footer={this.state.list.length} />
                                            <Column colSpan={3} style={{ fontSize: "12px", textAlign: "right" }} footer="Total dos pedidos" />
                                            <Column style={{ textAlign: "right" }} footer={toDecimal(Math.round(this.state.list.map((item) => item.totalM2).reduce((a, b) => a + b, 0)))} />
                                            <Column style={{ textAlign: "right" }} footer={toCurrency(this.state.list.map((item) => item.valorTotal).reduce((a, b) => a + b, 0))} />
                                            <Column colSpan={2} />
                                        </Row>
                                    </ColumnGroup>
                                }>
                                {this.columns}
                            </TZMTable>
                        </TZMPanel>
                    </div>
                    <div className="ui-g-3">
                        <PrevisaoLancamentoRFID
                            regional = {this.state.regional}
                            onLancadoHojeMes={() => this.withParams({ empresa: TZMUserData.getEmpresaRFID(), painel: "true", pronto: this.state.producao ? "true" : "false", cliente: null, produto: null, inclusao: this.rangeDiario(), entrega: this.rangeMensal() })}
                            onLancadoHojeFuturo={() => this.withParams({ empresa: TZMUserData.getEmpresaRFID(), painel: "true", pronto: this.state.producao ? "true" : "false", cliente: null, produto: null, inclusao: this.rangeDiario(), entrega: this.rangeMensal(1, null) })}
                            onLancadoHojeExportacao={() => this.withParams({ empresa: TZMUserData.getEmpresaRFID(), painel: "true", pronto: this.state.producao ? "true" : "false", cliente: null, produto: null, inclusao: this.rangeDiario(), uf: "EX", entrega: [null, null] })}
                            onLancadoHojeNacional={() => this.withParams({ empresa: TZMUserData.getEmpresaRFID(), painel: "true", pronto: this.state.producao ? "true" : "false", cliente: null, produto: null, inclusao: this.rangeDiario(), uf: "BR", entrega: [null, null] })}
                            onFaturado={() => this.withParams({ empresa: TZMUserData.getEmpresaRFID(), painel: "true", pronto: this.state.producao ? "true" : "false", cliente: null, produto: null, status: "FATURADO", faturamento: this.rangeMensal(), inclusao: [null, null], entrega: [null, null] })}
                            onCarteira={() => this.withParams({ empresa: TZMUserData.getEmpresaRFID(), painel: "true", pronto: this.state.producao ? "true" : "false", cliente: null, produto: null, status: "CARTEIRA", entrega: this.rangeMensal(), inclusao: [null, null] })}
                            onBloqueado={() => this.withParams({ empresa: TZMUserData.getEmpresaRFID(), painel: "true", pronto: this.state.producao ? "true" : "false", cliente: null, produto: null, status: "BLOQUEADO", entrega: this.rangeMensal(), inclusao: [null, null] })}
                            onTotalGeralM2={this.totalGeralM2}
                            onPedidosAtraso={() => this.withParams({ empresa: TZMUserData.getEmpresaRFID(), painel: "true", pronto: this.state.producao ? "true" : "false", cliente: null, produto: null, status: "ATRASO", entrega: [null, null], inclusao: [null, null] })}
                            onPedidosProntos={() => this.withParams({ empresa: TZMUserData.getEmpresaRFID(), painel: "true", pronto: "false", cliente: null, produto: null, status: "PRONTO", faturamento: [null, null], inclusao: [null, null], entrega: this.rangeMensal() })}
                        />
                        <div style={{ height: "8px" }} />
                        <Panel header="Legenda">
                            <div className="ui-g">                                
                                <div style={{ color: '#F44336', textAlign: "center" }} className="ui-g-1"><i className="fa fa-lock" /></div><div className="ui-g-5">Bloqueado</div>
                                <div style={{ color: '#607D8B', textAlign: "center" }} className="ui-g-1"><i className="fa fa-dolly-flatbed" /></div><div className="ui-g-5">Produzir</div>
                                <div style={{ color: '#607D8B', textAlign: "center" }} className="ui-g-1"><i className="fa fa-wallet" /></div><div className="ui-g-5">Em carteira</div>
                                <div style={{ color: '#4CAF50', textAlign: "center" }} className="ui-g-1"><i className="fa fa-pallet" /></div><div className="ui-g-5">Pronto</div>                               
                                <div style={{ color: '#4CAF50', textAlign: "center" }} className="ui-g-1"><i className="fa fa-check" /></div><div className="ui-g-5">Faturado</div>
                            </div>
                        </Panel>
                    </div>
                </div>                
                <TZMShortcut active={TZMShortcut.modalCount === 0}
                    onEnter={this.handleList}
                    onCtrlH={this.toggleHidden}
                    onCtrlN={this.newPedido}
                />
                <div className="content-section implementation p-fluid">
                    <Growl ref={(el) => this.growl = el} baseZIndex={10001002}></Growl>
                </div>
                <span className="ui-tzm-dashboard-title">{empresaNome} - {title}</span>
                <ModalRealm />
            </TZMMainContent>
        );
    }
}
