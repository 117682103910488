import { Fetch } from "../../utilities/Fetch";
import { httpGet, httpGetAsync } from "../../utilities/TZMRequest";
const empresa = process.env.REACT_APP_EMPRESA;
export class VwProdutoService {

	autoCompletar(query) {
		return Fetch.GetJSON(`/vw-produtos?produto=${query}&empresa=${empresa}&page=0&size=40&sort=descricao`);
	}

	listarSimples(query) {
		return Fetch.GetJSON(`/vw-produtos/listar-simples?produto=${query}&empresa=${empresa}&page=0&size=40&sort=descricao`);
	}

	produtoPreco = async (params) =>{
		return await httpGetAsync("/vw-produtos/produto-preco", params, true);
	}

	listar(params) {
		return httpGet("/vw-produtos", params);
	}
}
 