import React  from 'react';

export default class UsuarioTemplate {

	static usuarioTemplate(usuario){
		return (
			<div className="ui-tzm-suggestion-line">
				<div>{usuario.nome + ' | '+ usuario.email}</div>				
			</div>
		);
	}    

}