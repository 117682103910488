import moment from 'moment';
import { Tooltip } from 'primereact/components/tooltip/Tooltip';
import React from 'react';
import { clearInterval, setInterval } from 'timers';
import { toCurrencyWithoutCents, toPercent } from '../../../components/common/TZMFormatter';
import TZMTextFieldPainel from '../../../components/common/TZMTextFieldPainel';
import TZMMainContent from '../../../components/main/TZMMainContent';
import { TZMMenubarInfo } from '../../../components/menubar/TZMMenubarInfo';
import { ModalRealm } from '../../../components/modal/ModalRealm';
import TZMPanel from '../../../components/panel/TZMPanel';
import TZMColumnPainel from '../../../components/table/TZMColumnPainel';
import TZMTable from '../../../components/table/TZMTable';
import { Fetch } from '../../../utilities/Fetch';
import TZMUserData from '../../../utilities/TZMUserData';
import { PainelService } from '../../servicos/PainelService';

const title = "Painel Moeda";
const empresaNome = "RFID";

export default class PainelMoeda extends React.Component {

    constructor() {
        super();
        this.state = {
            countdown: 0,
            pedidoOperador: [],
            pedidoVendedor: [],
            dashboard: {
                meta: { rfid: 0 },
                bloqueadoNacional: 0,
                precoMedioBloqueadoNacional: 0,
                carteiraNacional: 0,
                precoMedioCarteiraNacional: 0,
                bloqueadoExportacao: 0,
                precoMedioBloqueadoExportacao: 0,
                carteiraExportacao: 0,
                precoMedioCarteiraExportacao: 0,
                faturadoNacional: 0,
                faturadoExportacao: 0,
                devolucaoNacional: 0,
                devolucaoExportacao: 0,
                lancadoHojeMesNacional: 0,
                lancadoHojeMesExportacao: 0,
                lancadoHojeMesFuturoNacional: 0,
                lancadoHojeMesFuturoExportacao: 0,
                lancadoMesNacional: 0,
                lancadoMesExportacao: 0,
                lancadoMesFuturoNacional: 0,
                lancadoMesFuturoExportacao: 0,
                totalCarteiraBloqueado:0
            }
        };

        this.PainelService = new PainelService();
        this.reloadPreview = this.reloadPreview.bind(this);
        moment.locale('pt-BR');
    }
    reloadInterval = null;

    componentWillMount() {
        if (TZMUserData.isAutenticado()) {
            Fetch.Get("/ping");
            this.realoadInterval = setInterval(this.reloadPreview, 1000);            
        }
    }

    componentWillUnmount() {
        clearInterval(this.realoadInterval);
    }

    toPercent(rowData, column) {
        return <div style={{ textAlign: "right" }}>{toPercent(rowData[column.field])}</div>
    }


    pedidosOperador = [
        <TZMColumnPainel body={(rowData) => <div style={{ fontSize: "25px", fontWeight: "bold" }}>{rowData["operador"]}</div>} field="operador" key="operador" style={{ width: "*" }} header={<span style={{ fontSize: "20px" }}>Pedidos por Operador</span>} />,        
        <TZMColumnPainel body={(rowData) => <div style={{ textAlign: "right", fontSize: "25px", fontWeight: "bold" }}>{toCurrencyWithoutCents(Math.round(rowData["valorFaturamentoLiquido"]))}</div>} field="valorLiquido" key="valorLiquido" style={{ width: "13em" }} header={<span style={{ fontSize: "20px" }}>Volume </span>} />,
        <TZMColumnPainel body={(rowData) => <div style={{ textAlign: "right", fontSize: "25px", fontWeight: "bold" }}>{rowData["precoMedio"].toCurrency()}</div>} field="precoMedio" key="precoMedio" header={<div id="preco" children="Preço Médio" style={{ fontSize: "20px" }} />} style={{ width: "15em" }} />
    ];

    pedidosVendedor = [
        <TZMColumnPainel body={(rowData) =>
            <div title={`Código do Vendedor: ` + rowData["vendedorId"]} style={{ fontSize: "25px", fontWeight: "bold" }}>{rowData["nome"]}</div>}
            field="nome" key="nome" header={<span style={{ fontSize: "20px" }}>
                Pedidos por Vendedor</span>} style={{ width: "*" }} />,
        <TZMColumnPainel body={(rowData) => <div style={{ textAlign: "right", fontSize: "25px", fontWeight: "bold" }}>{toCurrencyWithoutCents(Math.round(rowData["valorFaturamentoLiquido"]))}</div>} field="valorLiquido" key="valorLiquido" header={<span style={{ fontSize: "20px" }}>Volume $</span>} style={{ width: "15em" }} />,
    ];

    async reloadPreview() {
        if (this.state.countdown > 1) {
            this.setState({ countdown: this.state.countdown - 1 });
        } else {
            this.setState({ countdown: 60 });
            await this.PainelService.pedidoOperadorVolume().then((response) => {
                this.setState({ pedidoOperador: response });
            });
            await this.PainelService.pedidoVendedorVolume().then((response) => {
                this.setState({ pedidoVendedor: response });
            });
            await this.PainelService.listarMoeda().then((response) => {
                this.setState({ dashboard: response });
            });

        }
    }

    render() {
        return (
            <TZMMainContent>
                <div className="ui-g">
                    <div className="ui-g-8 ui-g-nopad">
                        <div className="ui-g-6">
                            <Tooltip for="#preco" title="Preço Médio = (quantidade * valor unitário) - ICM / valor m2 " tooltipPosition="right" />
                            <TZMTable rows={25} paginator value={this.state.pedidoOperador}>
                                {this.pedidosOperador}
                            </TZMTable>
                        </div>
                         <div className="ui-g-6">
                            <TZMTable rows={15} paginator value={this.state.pedidoVendedor}>
                                {this.pedidosVendedor}
                            </TZMTable>
                        </div>
                        <div className="ui-g-12">
                            <TZMPanel>
                                <div className="ui-g" style={{ textAlign: "center" }}>
                                    <div className="ui-g-12">
                                        <div className="ui-g" style={{ margin: 0, padding: 0, textAlign: "center" }}>
                                            <div className="ui-g-2">
                                                <div className="ui-g-12">
                                                    <TZMTextFieldPainel readOnly  style={{ textAlign: "center", height: "35px", fontSize: "32px", padding: "0" }} label="Bloqueado Local" value={toCurrencyWithoutCents(Math.round(this.state.dashboard.bloqueadoNacional))} />
                                                </div>
                                            </div>
                                            <div className="ui-g-2">
                                                <div className="ui-g-12">
                                                    <TZMTextFieldPainel readOnly  style={{ textAlign: "center", height: "35px", fontSize: "32px", padding: "0" }} label="Carteira Local" value={toCurrencyWithoutCents(Math.round(this.state.dashboard.carteiraNacional))} />
                                                </div>
                                            </div>
                                            <div className="ui-g-2">
                                                <div className="ui-g-12">                                                    
                                                    <TZMTextFieldPainel readOnly  style={{ textAlign: "center", height: "35px", fontSize: "32px", padding: "0" }} label="Faturado Local" value={toCurrencyWithoutCents(Math.round(this.state.dashboard.faturadoNacional))} />
                                                </div>
                                            </div>
                                            <div className="ui-g-2">
                                                <div className="ui-g-12">
                                                    <TZMTextFieldPainel readOnly  style={{ textAlign: "center", height: "35px", fontSize: "32px", padding: "0" }} label="Devolução Local" value={toCurrencyWithoutCents(Math.round(this.state.dashboard.devolucaoNacional))} />
                                                </div>
                                            </div>
                                            <div className="ui-g-4">
                                                <div id={"totalLocal"} className="ui-g-12">
                                                    <Tooltip for="#totalLocal" title="Total = (carteira + bloqueado + faturado) - devolução " tooltipPosition="left" />
                                                    <TZMTextFieldPainel readOnly  style={{ textAlign: "center", height: "35px", fontSize: "32px", padding: "0" }} label="Total Local" value={toCurrencyWithoutCents(Math.round((this.state.dashboard.faturadoNacional + this.state.dashboard.carteiraNacional + this.state.dashboard.bloqueadoNacional) - this.state.dashboard.devolucaoNacional))} />
                                                </div>
                                            </div>

                                            <div className="ui-g-2">
                                                <Tooltip for="#bloqueadoExpo" title=" Valor = (valor unitário * cotação dolar (diária) ) " tooltipPosition="right" />
                                                <div id="bloqueadoExpo" className="ui-g-12">
                                                    <TZMTextFieldPainel readOnly  style={{ textAlign: "center", height: "35px", fontSize: "32px", padding: "0" }} label="Bloqueado Expo" value={toCurrencyWithoutCents(Math.round(this.state.dashboard.bloqueadoExportacao))} />
                                                </div>
                                            </div>
                                            <div className="ui-g-2">
                                                <Tooltip for="#carteiraExpo" title=" Valor = (valor unitário * cotação dolar (diária) ) " tooltipPosition="right" />
                                                <div id="carteiraExpo" className="ui-g-12">
                                                    <TZMTextFieldPainel readOnly  style={{ textAlign: "center", height: "35px", fontSize: "32px", padding: "0" }} label="Carteira Expo" value={toCurrencyWithoutCents(Math.round(this.state.dashboard.carteiraExportacao))} />
                                                </div>
                                            </div>
                                            <div className="ui-g-2">
                                                <Tooltip for="#faturadoExpo" title=" Valor = (valor unitário * cotação dolar (d - 1 da data de emissão do faturamento)) " tooltipPosition="right" />
                                                <div id="faturadoExpo" className="ui-g-12">
                                                    <TZMTextFieldPainel readOnly  style={{ textAlign: "center", height: "35px", fontSize: "32px", padding: "0" }} label="Faturado Expo" value={toCurrencyWithoutCents(Math.round(this.state.dashboard.faturadoExportacao))} />
                                                </div>
                                            </div>
                                            <div className="ui-g-2">
                                                <Tooltip for="#devolucaoExpo" title=" Valor = (valor unitário * cotação dolar (d - 1 da data de emissão do faturamento)) " tooltipPosition="right" />
                                                <div id="devolucaoExpo"  className="ui-g-12">
                                                    <TZMTextFieldPainel readOnly  style={{ textAlign: "center", height: "35px", fontSize: "32px", padding: "0" }} label="Devolução Expo" value={toCurrencyWithoutCents(Math.round(this.state.dashboard.devolucaoExportacao))} />
                                                </div>
                                            </div>
                                            <div className="ui-g-4">
                                                <div id={"totalExportacao"} className="ui-g-12">
                                                    <Tooltip for="#totalExportacao" title="Total = (carteira + bloqueado + faturado) - devolução " tooltipPosition="left" />
                                                    <TZMTextFieldPainel readOnly  style={{ textAlign: "center", height: "35px", fontSize: "32px", padding: "0" }} label="Total Expo" value={toCurrencyWithoutCents(Math.round((this.state.dashboard.faturadoExportacao + this.state.dashboard.carteiraExportacao + this.state.dashboard.bloqueadoExportacao) - this.state.dashboard.devolucaoExportacao))} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </TZMPanel>
                        </div>
                    </div>
                    <div className="ui-g-4">
                        <TZMPanel>
                            <div className="ui-g" style={{ textAlign: "center" }}>
                                <div className="ui-g-12">
                                    <TZMTextFieldPainel readOnly  style={{ fontSize: "40px", height: "45px", color: "#F44336", fontWeight: "bold", textAlign: "center" }} label="Lançado hoje para o Mês"
                                        value={toCurrencyWithoutCents(Math.round(this.state.dashboard.lancadoHojeMesNacional + this.state.dashboard.lancadoHojeMesExportacao))} />
                                </div>
                                <div className="ui-g-6">
                                    <TZMTextFieldPainel readOnly  style={{ fontSize: "32px", height: "35px", textAlign: "center" }} label="Local" value={toCurrencyWithoutCents(Math.round(this.state.dashboard.lancadoHojeMesNacional))} />
                                </div>
                                <div className="ui-g-6">
                                    <TZMTextFieldPainel readOnly  style={{ fontSize: "32px", height: "35px", textAlign: "center" }} label="Expo" value={toCurrencyWithoutCents(Math.round(this.state.dashboard.lancadoHojeMesExportacao))} />
                                </div>
                                <div className="ui-g-6">
                                    <TZMTextFieldPainel readOnly  style={{ fontSize: "32px", height: "35px", textAlign: "center", padding: "0" }} label="Lançado Hoje Mês"
                                        value={toCurrencyWithoutCents(Math.round(this.state.dashboard.lancadoHojeMesNacional + this.state.dashboard.lancadoHojeMesExportacao))} />
                                </div>
                                <div className="ui-g-6">
                                    <TZMTextFieldPainel readOnly  style={{ fontSize: "32px", height: "35px", textAlign: "center", padding: "0" }} label="Lançado hoje Fut"
                                        value={toCurrencyWithoutCents(Math.round(this.state.dashboard.lancadoHojeMesFuturoNacional + this.state.dashboard.lancadoHojeMesFuturoExportacao))} />
                                </div>
                                <div className="ui-g-12">
                                    <TZMTextFieldPainel readOnly  style={{ fontSize: "40px", height: "45px", fontWeight: "bold", textAlign: "center" }} label="Lançado mês para o mês"
                                        value={toCurrencyWithoutCents(Math.round(this.state.dashboard.lancadoMesNacional + this.state.dashboard.lancadoMesExportacao))} />
                                </div>
                                <div className="ui-g-6">
                                    <TZMTextFieldPainel readOnly  style={{ fontSize: "32px", height: "35px", textAlign: "center" }} label="Local"
                                        value={toCurrencyWithoutCents(Math.round(this.state.dashboard.lancadoMesNacional))} />
                                </div>
                                <div className="ui-g-6">
                                    <TZMTextFieldPainel readOnly  style={{ fontSize: "32px", height: "35px", textAlign: "center" }} label="Expo"
                                        value={toCurrencyWithoutCents(Math.round(this.state.dashboard.lancadoMesExportacao))} />

                                </div>

                                <div className="ui-g-12">
                                    <TZMTextFieldPainel readOnly  style={{ textAlign: "center", fontSize: "32px", height: "35px", fontWeight: "bold" }} label="Lançado no mês (Futura)"
                                        value={toCurrencyWithoutCents(Math.round(this.state.dashboard.lancadoMesFuturoNacional + this.state.dashboard.lancadoMesFuturoExportacao))} />
                                </div>
                                <div className="ui-g-6">
                                    <TZMTextFieldPainel readOnly  style={{ textAlign: "center", fontSize: "32px", height: "35px", fontWeight: "bold" }} label="Local"
                                        value={toCurrencyWithoutCents(Math.round(this.state.dashboard.lancadoMesFuturoNacional))} />

                                </div>
                                <div className="ui-g-6">
                                    <TZMTextFieldPainel readOnly  style={{ textAlign: "center", fontSize: "32px", height: "35px", fontWeight: "bold" }} label="Expo"
                                        value={toCurrencyWithoutCents(Math.round(this.state.dashboard.lancadoMesFuturoExportacao))} />

                                </div>

                                <div className="ui-g-12">
                                    <TZMTextFieldPainel readOnly  style={{ textAlign: "center", fontSize: "32px", height: "35px", fontWeight: "bold" }} label="CART + BLOQ" value={toCurrencyWithoutCents(Math.round(this.state.dashboard.totalCarteiraBloqueado))} />

                                </div>
                                <div className="ui-g-6">
                                    <TZMTextFieldPainel readOnly  style={{ textAlign: "center", fontSize: "32px", height: "35px", fontWeight: "bold" }} label="Local" value={toCurrencyWithoutCents(Math.round(this.state.dashboard.carteiraNacional + this.state.dashboard.bloqueadoNacional))} />
                                </div>
                                <div className="ui-g-6">
                                    <TZMTextFieldPainel readOnly  style={{ textAlign: "center", fontSize: "32px", height: "35px", fontWeight: "bold" }} label="Expo" value={toCurrencyWithoutCents(Math.round(this.state.dashboard.carteiraExportacao + this.state.dashboard.bloqueadoExportacao))} />
                                </div>

                            </div>
                        </TZMPanel>
                        <div style={{ height: "7px" }} />
                        <TZMPanel>
                            <div className="ui-g" style={{ padding: 0, textAlign: "center" }}>
                                <div className="ui-g-12">
                                    <TZMTextFieldPainel readOnly  style={{ textAlign: "center", fontSize: "40px", height: "45px", color: "#4286f4", fontWeight: "bold" }} label="Faturado no mês" value={toCurrencyWithoutCents(Math.round(this.state.dashboard.faturadoNacional + this.state.dashboard.faturadoExportacao))} />
                                </div>
                                <div className="ui-g-6">
                                    <TZMTextFieldPainel readOnly  style={{ fontSize: "32px", height: "35px", textAlign: "center" }} label="Faturado Local" value={toCurrencyWithoutCents(Math.round(this.state.dashboard.faturadoNacional))} />
                                </div>
                                <div className="ui-g-6">
                                    <TZMTextFieldPainel readOnly  style={{ fontSize: "32px", height: "35px", textAlign: "center" }} label="Faturado Expo" value={toCurrencyWithoutCents(Math.round(this.state.dashboard.faturadoExportacao))} />
                                </div>

                                <div className="ui-g-12">
                                    <TZMTextFieldPainel readOnly  style={{ textAlign: "center", fontSize: "40px", height: "45px", color: "#4286f4", fontWeight: "bold" }} label="Devolução no mês" value={toCurrencyWithoutCents(Math.round(this.state.dashboard.devolucaoNacional + this.state.dashboard.devolucaoExportacao))} />
                                </div>
                                <div className="ui-g-6">
                                    <TZMTextFieldPainel readOnly  style={{ fontSize: "32px", height: "35px", textAlign: "center" }} label="Devolução Local" value={toCurrencyWithoutCents(Math.round(this.state.dashboard.devolucaoNacional))} />
                                </div>
                                <div className="ui-g-6">
                                    <TZMTextFieldPainel readOnly  style={{ fontSize: "32px", height: "35px", textAlign: "center" }} label="Devolução Expo" value={toCurrencyWithoutCents(Math.round(this.state.dashboard.devolucaoExportacao))} />
                                </div>

                                <div id={"totalGeral"} className="ui-g-12">
                                    <Tooltip for="#totalGeral" title="Total = (carteira + bloqueado + faturado) - devolução " tooltipPosition="top" />
                                    <TZMTextFieldPainel readOnly  style={{ textAlign: "center", fontSize: "40px", height: "45px", color: "#4286f4", fontWeight: "bold" }} label="Total Geral mi" value={toCurrencyWithoutCents(Math.round(this.state.dashboard.totalCarteiraBloqueadoFaturado)) } />
                                </div>
                                <div className="ui-g-6">
                                    <TZMTextFieldPainel readOnly  style={{ textAlign: "center", fontSize: "32px", height: "35px", fontWeight: "bold" }} label="Local" value={toCurrencyWithoutCents(Math.round((this.state.dashboard.carteiraNacional + this.state.dashboard.bloqueadoNacional + this.state.dashboard.faturadoNacional) - this.state.dashboard.devolucaoNacional))} />
                                </div>
                                <div className="ui-g-6">
                                    <TZMTextFieldPainel readOnly  style={{ textAlign: "center", fontSize: "32px", height: "35px", fontWeight: "bold" }} label="Expo" value={toCurrencyWithoutCents(Math.round((this.state.dashboard.carteiraExportacao + this.state.dashboard.bloqueadoExportacao + this.state.dashboard.faturadoExportacao) - this.state.dashboard.devolucaoExportacao))} />
                                </div>
                            </div>
                            
                        </TZMPanel>
                    </div>
                </div>
                <div className="ui-g-6" style={{ textAlign: "left", fontSize: "15px", fontWeight: "bold" }}>
                    Valores líquidos livres de impostos
                </div>
                
                <span className="ui-tzm-dashboard-title">{empresaNome} - {title}</span>
                <TZMMenubarInfo countdown={this.state.countdown} />
                <ModalRealm />
            </TZMMainContent>
        );
    }
}

