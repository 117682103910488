import { Fetch } from "../../utilities/Fetch";

export class PainelService {

	pedidoOperadorVolume() {
		return Fetch.GetJSON("/rfid/painel/volume/operador");
	}

	pedidoVendedorVolume() {
		return Fetch.GetJSON("/rfid/painel/volume/vendedor");
	}

	listarVolume() {
		return Fetch.GetJSON("/rfid/painel/volume/listar");
	}

	listarMoeda() {
		return Fetch.GetJSON("/rfid/painel/moeda/listar");
	}

}
