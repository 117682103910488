import React from "react";
import TZMAutoComplete from "../../../components/common/TZMAutoComplete";
import TZMButton from "../../../components/common/TZMButton";
import TZMTextField from "../../../components/common/TZMTextField";
import TZMConfirm from "../../../components/dialog/TZMConfirm";
import TZMDialog from "../../../components/dialog/TZMDialog";
import TZMPopup from "../../../components/dialog/TZMPopup";
import { ModalRealm } from "../../../components/modal/ModalRealm";
import TZMPanel from "../../../components/panel/TZMPanel";
import TZMPanelFooter from "../../../components/panel/TZMPanelFooter";
import TZMColumn from "../../../components/table/TZMColumn";
import TZMTable from "../../../components/table/TZMTable";
import TZMShortcut from "../../../utilities/TZMShortcut";
import { Validator } from "../../../utilities/TZMValidator";
import { FamiliaService } from "../../servicos/FamiliaService";
import { SegmentoService } from "../../servicos/SegmentoService";


export class EditarSegmento extends React.Component {

	constructor(props) {
		super(props);
		this.state = {			
			visible: true,
			segmento: this.completarNulos(this.props.segmento)			
		};
		
		this.segmentoService = new SegmentoService();
		this.familiaService = new FamiliaService();
	}

	completarNulos(segmento) {
		if (!segmento.familias) {
			segmento.familias = [];
		}
		return segmento;
	}

	onHide = () => this.setState({visible: false});

	handleChange = (event) => {
		let segmento = this.state.segmento;
		segmento[event.name] = event.target.value;
		this.setState({segmento});
	}

	salvarSegmento = () => {
		if (this.validarSegmento()){
			let segmento = Object.assign({}, this.state.segmento);
			this.segmentoService.salvar(segmento).then((segmento) => {
				if (this.props.onModalClose) this.props.onModalClose(segmento);
				this.onHide();
			});
		}
	}

	handleClear = (event) => {
		let segmento = this.state.segmento;
		segmento[event.name] = null;
		this.setState({segmento});
	}

	handleSelect = (event) => {
		let segmento = this.state.segmento;
		segmento[event.name] = event.value;
		this.setState({segmento});
    }
   
	addFamilia = (event) => {
		let segmento = this.state.segmento;
		segmento.familias.unshift(event.value);
		segmento.familia = null;
		this.setState({segmento});
	}

	listarFamilias = async  (event) => {
		let remover = this.state.segmento.familias;	
		await this.familiaService.autoCompletar({familia: event.query,notin:remover.map((familia) => familia.id), sort:'descricao'}).then((familias) => this.setState({familias}));
	}

	excluirFamilia = (familia) => {
		ModalRealm.showDialog(<TZMConfirm onYes={() => {
		let segmento = this.state.segmento;
		segmento.familias =  segmento.familias.filter(f => f !== familia);
		this.setState({segmento});
		}} question="Tem certeza de que deseja remover esta família?" />);
	}

	columnsFamilias = [		
		<TZMColumn key="descricao" header="Descrição"  field="descricao" name="descricao"  style={{width: "*", textAlign: "center"}} />,		
		<TZMColumn key="acao" header="Ações" style={{width: "6em", textAlign: "center"}} body={(familia) => {
			return (
				<div>
					<span title="Remover Família" className="fa fa-trash-alt ui-tzm-icon" onClick={() => this.excluirFamilia(familia)} />
				</div>
			);
		}} />		
	];


	validarSegmento = () => {
		let messages = [];
		let segmento = this.state.segmento;
		if (Validator.isEmpty(segmento.descricao)) {
			messages.push("A descrição do segmento é obrigatório");
		}		
		if (messages.length > 0) {
			ModalRealm.showDialog(<TZMPopup header="Advertência" messages={messages} />);
			return false;
		}
		return true;
	}


	render() {
		return (
			<TZMDialog style={{width: "600px"}} visible={this.state.visible} modal header="Segmento" onHide={this.onHide} {...this.props}>
				<TZMPanel>
					<div className="ui-g">
						<div className="ui-g-12">
                            <TZMTextField label="Descrição" name="descricao" value={this.state.segmento.descricao} onChange={this.handleChange} />
                        </div>
                    </div>
				</TZMPanel>				
				<TZMPanel>
					<TZMAutoComplete onClear={this.handleClear}  onSelect={this.addFamilia} onChange={this.handleSelect} 
					suggestions={this.state.familias} completeMethod={this.listarFamilias}  placeholder="Descrição" name="familia" field="descricao" label="Família" 
					value={this.state.segmento.familia} />						
				</TZMPanel>	
				<TZMTable ref={(el) => this.dt = el} value={this.state.segmento.familias}> 
					{this.columnsFamilias} 
				</TZMTable>
				<TZMPanelFooter>
					<TZMButton success label="Salvar" icon="fas fa-save" onClick={this.salvarSegmento} />
					<TZMButton secondary label="Fechar" icon="fas fa-times" onClick={this.onHide} />
				</TZMPanelFooter>
				<TZMShortcut active={this.state.visible} onCtrlS={this.salvarSegmento} onEscape={this.onHide}/>
			</TZMDialog>
		);
	}

}
