import { httpDelete, httpGet, httpPost } from "../../utilities/TZMRequest";
import { Util } from "../../utilities/Util";
import { EntidadeService } from "./EntidadeService";

export class PedidoService extends EntidadeService {

	completar(pk) {
		return httpGet(`/pedidos/${pk}`);
	}

	clonar(pk) {
		return httpGet(`/pedidos/clonar/${JSON.stringify(pk)}`);
	}

	salvar(pedido) {
		pedido = this.cleanup(pedido);
		return httpPost("/pedidos", pedido);
	}
 
	listar(params) {
		return httpGet("/pedidos", {...params, sort: "pedidoId desc"});
	}
 
	listarSimples = (params) => {
		switch (params.status) {
			case "CARTEIRA":
			case "FATURADO":
			case "BLOQUEADO":
			case "PRONTO":
			case "ATRASO":
				return Util.emptyPromise([]);
			default:
				return httpGet("/pedidos/listarSimples/", { ...params, sort: "pedidoId desc" });
		}		
	}

	detalhar(pk) {
		return httpGet(`/pedidos/detalhe/${pk}`);
	}

	criar() {
		return httpGet("/pedidos/novo");
	}

	cancelar(pk) {
		return httpPost(`/pedidos/cancelar/${pk}`);
	}

	removerImagem(pk) {
		return httpDelete(`/public/arquivos`, pk);
	}

	buscar(pedidoID) {
		return httpGet(`/pedidos/id/${pedidoID}`);
	}
 
}
