import moment from "moment";
import { Button } from "primereact/components/button/Button";
import { Tooltip } from "primereact/components/tooltip/Tooltip";
import React from "react";
import TZMAutoComplete from "../../../components/common/TZMAutoComplete";
import TZMButton from "../../../components/common/TZMButton";
import TZMCalendar from "../../../components/common/TZMCalendar";
import TZMCombobox from "../../../components/common/TZMCombobox";
import { TZMCurrency } from "../../../components/common/TZMCurrency";
import { toCurrency, toPercent } from "../../../components/common/TZMFormatter";
import TZMIcon from "../../../components/common/TZMIcon";
import TZMTextArea from "../../../components/common/TZMTextArea";
import TZMTextField from "../../../components/common/TZMTextField";
import TZMConfirm from "../../../components/dialog/TZMConfirm";
import TZMDialog from "../../../components/dialog/TZMDialog";
import TZMPopup from "../../../components/dialog/TZMPopup";
import { ModalRealm } from "../../../components/modal/ModalRealm";
import TZMPanelFooter from "../../../components/panel/TZMPanelFooter";
import TZMTabPanel from "../../../components/panel/TZMTabPanel";
import TZMTabView from "../../../components/panel/TZMTabView";
import TZMColumn from "../../../components/table/TZMColumn";
import TZMTable from "../../../components/table/TZMTable";
import TZMShortcut from "../../../utilities/TZMShortcut";
import TZMUserData from "../../../utilities/TZMUserData";
import { asEmail } from "../../../utilities/TZMUtil";
import { Validator } from "../../../utilities/TZMValidator";
import { UF } from "../../../utilities/UF";
import { CFOPService } from "../../servicos/CFOPService";
import { VwClienteService } from "../../servicos/VwClienteService";
import { CondicaoPagamentoService } from "../../servicos/CondicaoPagamentoService";
import { EnumService } from "../../servicos/EnumService";
import { ImagemService } from "../../servicos/ImagemService";
import { IndiceService } from "../../servicos/IndiceService";
import { IntegracaoService } from "../../servicos/IntegracaoService";
import { LogEmailService } from "../../servicos/LogEmailService";
import { PedidoService } from "../../servicos/PedidoService";
import { ListaPrecoService } from "../../servicos/ListaPrecoService";
import { TipoMovimentoService } from "../../servicos/TipoMovimentoService";
import { TransportadoraService } from "../../servicos/TransportadoraService";
import { UsuarioService } from "../../servicos/UsuarioService";
import { VwProdutoService } from "../../servicos/VwProdutoService";
import { VwVendedorService } from "../../servicos/VwVendedorService";
import { EditarItemPedido } from "./EditarItemPedido";
import { LogIntegracao } from "../../comum/pedido/LogIntegracao";
import { MotivoBloqueio } from "../../comum/pedido/MotivoBloqueio";

const basePath = process.env.REACT_APP_API_HOST;
export default class EditarPedido extends React.Component {

	constructor(props) {
		super(props);
		moment.locale('pt-BR');
		this.imagemService = new ImagemService();
		this.pedidoService = new PedidoService();
		this.usuarioService = new UsuarioService();
		this.condicaoPagamentoService = new CondicaoPagamentoService();
		this.state = {
			editavel: props.pedido.sistema !== "OMEGA" && (props.pedido.status === "RASCUNHO" || props.pedido.status === "ERRO" || (props.pedido.itens && props.pedido.itens.some(i => i.status === "ERRO"))),
			pedido: this.completarNulos(props.pedido),
			visible: true,
			alterado: false,
			selectedIndex: -1,
			maisInfoCliente: false,
			firstItem: 0
		};

		this.vwClienteService = new VwClienteService();
		this.transportadoraService = new TransportadoraService();
		this.vwVendedorService = new VwVendedorService();
		this.tipoMovimentoService = new TipoMovimentoService();
		this.vwProdutoService = new VwProdutoService();
		this.listaPrecoService = new ListaPrecoService();
		this.cfopService = new CFOPService();
		this.enumService = new EnumService();
		this.indiceService = new IndiceService();
		this.logEmailService = new LogEmailService();
		this.integracaoService = new IntegracaoService();
	}

	componentWillMount() {
		if (this.state.editavel) {
			this.tipoMovimentoService.listarCombobox().then((tiposMovimento) => {
				if (tiposMovimento.length > 0) {
					this.setState({ tiposMovimento: tiposMovimento.map(tm => { return { descriptor: `${tm.pk.tipoMovimentoId} - ${tm.descricao}`, value: JSON.stringify(tm) } }) });
				}
			});			
		} else {			
			this.setState({ tiposMovimento: [{ descriptor: `${this.state.pedido.tipoMovimento.pk.tipoMovimentoId} - ${this.state.pedido.tipoMovimento.descricao}`, value: JSON.stringify(this.state.pedido.tipoMovimento) }] });
		}
		if (this.state.editavel) {
			this.indiceService.listar().then((indices) => {
				indices = indices.map(indice => <option key={indice.pk.indiceId} value={JSON.stringify(indice)}>{`${indice.pk.indiceId} - ${indice.descricao}`}</option>);
				indices.unshift(<option key={0} value={null}></option>);
				this.setState({ indices });
			});			
		} else if (this.state.pedido.indice != null) {
			this.setState({ indices: [<option key={this.state.pedido.indice.pk.indiceId} value={JSON.stringify(this.state.pedido.indice)}>{`${this.state.pedido.indice.pk.indiceId} - ${this.state.pedido.indice.descricao}`}</option>] })
		}
		if (this.state.pedido.sistema === "OMEGA" && this.state.pedido.id && this.state.pedido.adiantamento) {
			this.logEmailService.findLogEmailForPedido(this.state.pedido.id).then((logEmail) => this.setState({ logEmail }));
		}
	}

	componentDidMount() {
		let pedido = this.state.pedido;
		if (pedido.id == null && pedido.sistema === 'TZM') {
			this.usuarioService.completar(TZMUserData.getUsuario()).then((usuario => {
				pedido.criadoPor = usuario;
				pedido.operador = usuario.operador;
				pedido.emailAdiantamento = usuario.ultimoEmail;
				pedido.gestor = usuario.gestor;
				this.setState({ pedido });
			}));
		} 
		
		if (this.state.pedido.vendedor.pk.vendedorId) {
			this.selectVendedores({ value: this.state.pedido.vendedor.pk });		
		} 

		if (this.state.pedido.status === "CONFIRMADO" || this.state.pedido.status === "ERRO") {			
			this.state.pedido.itens.forEach((itemPedido) => {
				this.integracaoService.statusItemPedido(this.state.pedido.pedidoId, itemPedido.itemId).then((status) => {
					itemPedido.status = status;
					this.forceUpdate();
				});

			});
		}		
	}

	completarNulos(pedido) {
		if (pedido.sistema === 'TZM' && pedido.tipoMovimento !== null && pedido.tipoMovimento.bonificacao) {
			this.condicaoPagamentoService.bonificacao(pedido.tipoMovimento.pk.empresaId).then((bonificacao => {
				pedido.condicaoPagamento = bonificacao;
			}));
		}
		if (!pedido.cliente) {
			pedido.cliente = { pk: {}, razaoSocial: "", descricaoAmigavel: "" };
		}
		if (!pedido.transportadora) {
			pedido.transportadora = { pk: {}, razaoSocial: "", descricaoAmigavel: "" };
		}
		if (!pedido.transportadoraRedespacho) {
			pedido.transportadoraRedespacho = { pk: {}, razaoSocial: "", descricaoAmigavel: "" };
		}
		if (!pedido.vendedor) {
			pedido.vendedor = { pk: {}, nome: " ", descricaoAmigavel: " " };
		}
		if (!pedido.cfop) {
			pedido.cfop = { pk: {}, descricao: "", descricaoAmigavel: "" };
		}
		if (!pedido.condicaoPagamento) {
			pedido.condicaoPagamento = { pk: {}, descricao: "", descricaoAmigavel: "" };
		}
		if (!pedido.itens) {
			pedido.itens = [{ itemId: 1, valorUnitario: 0, valorTotal: 0, valorTotalItem: 0, valorIpi: 0, percentualIpi: 0, produto: { pk: {}, descricao: "", percentualIpi:"0"}, quantidade: "0", desconto: 0, descontoPercentual: "0" }];
		} else {
			pedido.itens.forEach((item) => {
				if (!item.desconto) item.desconto = 0;
				if (!item.descontoPercentual) item.descontoPercentual = "0";
				if (!item.valorUnitario) item.valorUnitario = 0;
				if (!item.quantidade) item.quantidade = "0";
				if (!item.valorTotal) item.valorTotalItem = (item.valorUnitario * item.quantidade);
				if (pedido.sistema === 'TZM') {
					item.percentualIpi = Number(item.produto.percentualIpi);
					item.valorIpi = (Number(item.valorUnitario) * Number(item.quantidade) * (Number(item.percentualIpi)));
				}
				if (!item.valorTotal) item.valorTotal = (item.valorUnitario * item.quantidade) + item.valorIpi;
				if (item.bobina != null && item.bobina.cortes != null) {
					item.bobina.cortes = item.bobina.cortes.sort((a, b) => a.itemId - b.itemId);
				}
			});
			pedido.itens = pedido.itens.sort((a, b) => a.itemId - b.itemId);
		}
		if (!pedido.textoEspecifico) {
			pedido.textoEspecifico = [{ texto: "" }];
		} else if (pedido.textoEspecifico.length === 0) {
			pedido.textoEspecifico.push({ texto: "" });
		}
		if (!pedido.textoInterno) {
			pedido.textoInterno = [{ texto: "" }];
		} else if (pedido.textoInterno.length === 0) {
			pedido.textoInterno.push({ texto: "" });
		}
		if (pedido.valorFrete == null) {
			pedido.valorFrete = 0;
		}
		if (pedido.adiantamento == null) {
			pedido.adiantamento = 0;
		}
		if (pedido.imagem == null) {
			pedido.imagem = {};
		}
		pedido = this.preencherEnderecos(pedido);
		if (pedido.sistema === "OMEGA" && pedido.imagem != null) {
			this.imagemService.buscarImagemDoPedido(pedido.imagem.pk.pedidoId).then(imagem => {
				pedido.imagem = { file: imagem };
				this.setState({ pedido });
			});
		}
		return pedido;
	}

	preencherEnderecos(pedido) {
		if (pedido.cliente.enderecos) {
			pedido.cliente.enderecos.forEach((endereco) => {
				if (endereco.pk.tipo === "COBRANCA") {
					pedido.cliente.enderecoCobranca = endereco;
				}
				if (endereco.pk.tipo === "ENTREGA") {
					pedido.cliente.enderecoEntrega = endereco;
				}
			});
		} else {
			pedido.cliente.enderecos = [];
		}
		return pedido;
	}

	salvarPedido = () => {

		if (this.validarPedido()) {
			let pedido = this.state.pedido;
			if (pedido.itens) {
				let maxItem = pedido.itens.map(i => Number(i.itemId)).filter(v => !isNaN(v)).reduce((a, b) => Math.max(a, b), 0);
				pedido.itens.forEach((item) => {
					if (item.itemId == null) item.itemId = ++maxItem;
					if (pedido.status === "CONFIRMADO") {
						if (pedido.novo) {
							item.tipoManutencao = "INCLUSAO";
						} else if (item.alterado) {
							item.tipoManutencao = "ALTERACAO";
						}
					}
					if (item.cfop == null) item.cfop = pedido.cfop;
					if (item.tipoMovimento == null) item.tipoMovimento = pedido.tipoMovimento;
					if (item.bobina != null) {

						let maxGrade = item.bobina.cortes.map(c => Number(c.itemId)).filter(v => !isNaN(v)).reduce((a, b) => Math.max(a, b), 0);
						if (item.bobina.cortes != null) {
							item.bobina.cortes.forEach(corte => {
								if (corte.itemId == null) corte.itemId = ++maxGrade;
							});
						}
						if (pedido.status === "CONFIRMADO") {
							if (item.bobina.id == null || pedido.novo) {
								item.bobina.tipoManutencao = "INCLUSAO";
							} else if (item.bobina.alterado) {
								item.bobina.tipoManutencao = "ALTERACAO";
							}
							let totalGrades = item.bobina.cortes.map(c => c.grade).reduce((a, b) => Math.max(a, b), 0);
							for (let i = 1; i <= totalGrades; ++i) {
								let sample = item.bobina.cortes.filter(c => c.grade === i)[0];
								let aproveitamento = item.bobina.cortes.filter(c => c.grade === i).map(c => c.largura * c.numero).reduce((a, b) => a + b, 0);
								if (item.bobina.largura > aproveitamento) {
									let m2 = (item.bobina.largura - aproveitamento) * item.bobina.comprimento / 1000;
									let largura = item.bobina.largura - aproveitamento;
									let total = m2 > 0 ? Math.ceil(sample.totalM2CompradoGrade / (item.bobina.largura * item.bobina.comprimento / 1000)) : 0;
									item.bobina.cortes.push({ ponta: true, totalM2: sample.totalM2, m2, total, totalM2CompradoGrade: sample.totalM2CompradoGrade, itemId: ++maxGrade, grade: i, largura, numero: 1, aproveitamento: largura, quantidadeTiradas: sample.quantidadeTiradas });
								}
							}
							item.bobina.cortes.forEach(c => c.ponta = c.ponta || false);
							item.bobina.cortes = item.bobina.cortes.sort((a, b) => {
								if (a.grade < b.grade) return -1; else if (b.grade < a.grade) return 1;
								if (a.ponta !== b.ponta) return a.ponta ? 1 : -1;
								return a.itemId - b.itemId;
							});
							let itemId = 0;
							let nextGrade = 1;
							item.bobina.cortes.forEach((i) => {
								i.itemId = ++itemId;
								if (nextGrade !== i.grade) {
									i.quantidadeTiradas = 0;
								} else {
									++nextGrade;
								}
							});
						}
					}
				});
			}
			if (pedido.textoInterno != null && pedido.textoInterno.length > 0) {
				pedido.textoInterno = pedido.textoInterno.filter(t => t.id != null || (t.texto != null && t.texto !== ""));
				pedido.textoInterno.forEach(textoInterno => {
					if (pedido.status === "CONFIRMADO") {
						if (textoInterno.id == null || !textoInterno.alterado) {
							textoInterno.tipoManutencao = "INCLUSAO";
						} else {
							textoInterno.tipoManutencao = "ALTERACAO";
						}
					}
				});
			}
			if (pedido.textoEspecifico != null && pedido.textoEspecifico.length > 0) {
				pedido.textoEspecifico = pedido.textoEspecifico.filter(t => t.id != null || (t.texto != null && t.texto !== ""));
				pedido.textoEspecifico.forEach(textoEspecifico => {
					if (pedido.status === "CONFIRMADO") {
						if (textoEspecifico.id == null || !textoEspecifico.alterado) {
							textoEspecifico.tipoManutencao = "INCLUSAO";
						} else {
							textoEspecifico.tipoManutencao = "ALTERACAO";
						}
					}
				});
			}
			if (pedido.imagem != null && pedido.imagem.conteudo != null) {
				pedido.criadoPor.ultimoEmail = pedido.emailAdiantamento;
				pedido.imagem.cascadeable = true;
				pedido.imagem.conteudo = pedido.imagem.conteudo.split(",").pop();
			}
			this.pedidoService.salvar(pedido).then((response) => {
				this.setState({ visible: false });
				if (this.props.onSave) {
					this.props.onSave(response);
				}
			});
		}
	}

	percentFormat = (rowData, column) => {
		return <div style={{ textAlign: "right" }}>{toPercent(rowData[column.field])}</div>;
	}

	fecharPainel = () => {
		this.setState({ visible: false });
		if (this.props.onClose) {
			this.props.onClose(this.state.pedido);
		}
	}

	fecharPedido = () => {
		if (this.state.alterado && this.state.pedido.sistema === 'TZM') {
			ModalRealm.showDialog(<TZMConfirm key={TZMUserData.getIdGenerator()} onYes={this.fecharPainel} question="Fechar e descartar as alterações feitas?" />);
		} else {
			this.fecharPainel();
		}
	}

	validarPedido = () => {
		let messages = [];
		let pedido = this.state.pedido;
		if (!Validator.isEntidade(pedido.cliente)) {
			messages.push("O cliente é obrigatório");
		}
		if (!Validator.isEntidade(pedido.transportadora)) {
			messages.push("A transportadora é obrigatória");
		}
		if (!Validator.isEntidade(pedido.vendedor)) {
			messages.push("O vendedor é obrigatório");
		}
		if (!Validator.isEntidade(pedido.tipoMovimento)) {
			messages.push("O tipo de movimento é obrigatório");
		}
		if (!Validator.isEntidade(pedido.cfop)) {
			messages.push("O CFOP é obrigatório");
		}
		if (!Validator.isEntidade(pedido.condicaoPagamento)) {
			messages.push("A condição de pagamento é obrigatória");
		}
		if (Validator.isEmpty(pedido.dataPedido)) {
			messages.push("A data do pedido é obrigatória");
		}
		if (Validator.isEmpty(pedido.dataPedidoCliente)) {
			messages.push("A data do pedido do cliente é obrigatória");
		}
		if (Validator.isEmpty(pedido.formaPagamento)) {
			messages.push("A forma de pagamento é obrigatória");
		}
		if (Validator.isEmpty(pedido.dataEntrega)) {
			messages.push("A data de entrega é obrigatória");
		}
		if (Validator.isEmpty(pedido.frete)) {
			messages.push("O tipo do frete é obrigatório");
		}
		if (pedido.dataPedido != null && pedido.dataEntrega != null && pedido.dataPedidoCliente != null) {
			let dataPedido = new Date(pedido.dataPedido);
			let dataEntrega = new Date(pedido.dataEntrega);
			let dataPedidoCliente = new Date(pedido.dataPedidoCliente);

			if (moment(pedido.dataEntrega).weekday() === 0) {
				messages.push("A data de entrega não pode ser no Domingo.");
			}			
			dataEntrega.setDate(dataEntrega.getDate() - 3);//Data de entrega deve ser sempre maior que 3 dias que a data do pedido.
			if (dataPedido > dataEntrega) {
				messages.push("A data de entrega deve ser 3 dias maior que a data do pedido.");
			}
			if (dataPedidoCliente > dataEntrega) {
				messages.push("A data de entrega deve ser 3 dias maior que a data do pedido do cliente.");
			}
			if (dataPedidoCliente < dataPedido) {
				messages.push("A data de pedido do cliente deve ser maior ou igual a data do pedido.");
			}
			if (pedido.dataMinimaFaturamento != null || pedido.dataPrevisaoFaturamento != null) {
				if (pedido.dataMinimaFaturamento != null && moment(pedido.dataEntrega).isBefore(pedido.dataMinimaFaturamento)) {
					messages.push("A data de entrega deve ser maior ou igual que a data mínima para faturamento.");
				}
				if (pedido.dataPrevisaoFaturamento != null && moment(pedido.dataEntrega).isBefore(pedido.dataPrevisaoFaturamento)) {
					messages.push("A data de entrega deve ser maior ou igual que a data prevista para faturamento.");
				}
				if (pedido.dataMinimaFaturamento != null && pedido.dataPrevisaoFaturamento != null &&
					moment(pedido.dataMinimaFaturamento).isAfter(pedido.dataPrevisaoFaturamento)) {
					messages.push("A data mínima deve ser menor ou igual que a data prevista de faturamento.");
				}
				if (pedido.dataMinimaFaturamento != null && moment(pedido.dataPedido).isAfter(pedido.dataMinimaFaturamento)) {
					messages.push("A data mínima para faturamento deve ser maior ou igual que a data do pedido.");
				}
				if (pedido.dataPrevisaoFaturamento != null && moment(pedido.dataPedido).isAfter(pedido.dataPrevisaoFaturamento)) {
					messages.push("A data prevista para faturamento deve ser maior ou igual que a data do pedido.");
				}

			}
		}
		if (pedido.itens == null || pedido.itens.length === 0) {
			messages.push("O pedido deve ter ao menos um item");
		} else {
			if (Validator.isEntidade(pedido.tipoMovimento) && pedido.itens.some(i => i.tipoMovimento != null) && !pedido.itens.some(i => i.tipoMovimento == null) && !pedido.itens.some(i => i.tipoMovimento != null && i.tipoMovimento.pk.tipoMovimentoId === pedido.tipoMovimento.pk.tipoMovimentoId)) {
				messages.push("Ao menos um item do pedido deve ter tipo de movimento igual ao do pedido");
			}
			if (Validator.isEntidade(pedido.cfop) && pedido.itens.some(i => i.cfop != null) && !pedido.itens.some(i => i.cfop == null) && !pedido.itens.some(i => i.cfop != null && i.cfop.pk.cfopId === pedido.cfop.pk.cfopId)) {
				messages.push("Ao menos um item do pedido deve ter CFOP igual ao do pedido");
			}
			pedido.itens.forEach((item) => {
				if (!Validator.isEntidade(item.produto)) {
					messages.push("Existem itens do pedido sem produto preenchido");
				}
				if (!item.valorUnitario || item.valorUnitario === 0 || !item.valorTotalItem || item.valorTotalItem === 0) {
					messages.push("O valor unitário dos produtos deve ser maior que zero");
				}
				if (Validator.isEmpty(item.codigoTributario)) {
					messages.push("O código tributário do item do pedido é obrigatório");
				}
				if (item.bobina && item.bobina.m2corte) {
					let cortar = item.bobina.m2Corte.reduce((a, b) => a + b, 0);
					if (cortar !== Number(item.quantidade)) {
						messages.push("O total a ser cortado (" + cortar + " m²) difere da quantidade comprada (" + item.quantidade + " m²).");
					}
				}
			});
		}
		if (pedido.cliente.exportacao) {
			if (Validator.isEmpty(pedido.localidadeEmbarque)) {
				messages.push("A localidade de embarque para exportações é obrigatória");
			}
			if (Validator.isEmpty(pedido.ufEmbarque)) {
				messages.push("A UF de embarque para exportações é obrigatória");
			}
			if (Validator.isEmpty(pedido.ordemVenda)) {
				messages.push("O número da ordem de venda para exportações é obrigatória");
			}
			if (Validator.isEmpty(pedido.indice)) {
				messages.push("O índice da moeda para exportações é obrigatória");
			}
		}
		if (messages.length > 0) {
			ModalRealm.showDialog(<TZMPopup key={TZMUserData.getIdGenerator()} header="Advertência" messages={messages} />);
			return false;
		}
		return true;
	}

	listClientes = async (event) => {
        await this.vwClienteService.autoComplete({cliente: event.query,posicao: 'ATIVO', sort: 'razao'}).then((listClientes) => this.setState({listClientes}));
    }

	ultimoEmail = (event) => {
		this.pedidoService.ultimoEmail(event.query).then(ultimoEmail => this.setState({ ultimoEmail }));
	}

	selectCliente = (event) => {
		if (event.value != null) {
			let pedido = this.state.pedido;
			this.vwClienteService.buscar(event.value).then(cliente => {
				pedido.cliente = cliente;
				if ((pedido.tipoMovimento === null) || (pedido.tipoMovimento != null && !pedido.tipoMovimento.bonificacao)) {
					pedido.condicaoPagamento = pedido.cliente.condicaoPagamento;
				}
				pedido.formaPagamento = pedido.cliente.formaPagamento;
				 
				if (!TZMUserData.isRepresentante()) {
					if (pedido.cliente.vendedor != null && pedido.cliente.vendedor.nome != null) {
						pedido.vendedor = pedido.cliente.vendedor;
					}
				}
				this.preencherEnderecos(pedido);
				this.setState({ pedido, maisInfoCliente: this.state.maisInfoCliente && pedido.cliente.pk.clienteId, alterado: true });
			})
		}
	}

	listTransportadoras = (event) => {
		this.transportadoraService.autoCompletar(event.query).then((response) => {
			this.setState({ listTransportadoras: response });
		});
	}

	listTransportadorasRedespacho = (event) => {
		this.transportadoraService.autoCompletar(event.query).then((response) => {
			this.setState({ listTransportadorasRedespacho: response });
		});
	}

	listVendedores = (event) => {
		this.vwVendedorService.autoCompletar(event.query).then((response) => {
			this.setState({ listVendedores: response });
		});
	}

	selectVendedores = (event) => {
		let pedido = this.state.pedido;		
		let pk; 
		if (event.value.pk){
			pk = Object.assign({},  event.value.pk);
		} else {
			pk = Object.assign({},  event.value);
		}			
		this.vwVendedorService.buscar(pk).then((vendedor) => {
			pedido.vendedor = vendedor;
			pk.vendedorId = vendedor.pk.supervisorId;			
			this.vwVendedorService.buscar(pk).then((supervisor) => {
				pedido.supervisor = supervisor;
				pk.vendedorId = supervisor.pk.gerenteId;
				pk.supervisorId = supervisor.pk.gerenteId;
				this.vwVendedorService.buscar(pk).then((gerente) => {
					pedido.gerente = gerente;
					this.setState({ pedido });
				});
			});
		});
	}


	deleteItemPedido = (event, rowData) => {
		event.preventDefault();
		event.stopPropagation();
		if (Validator.isEntidade(rowData.produto)) {
			ModalRealm.showDialog(<TZMConfirm key={TZMUserData.getIdGenerator()} onYes={() => {
				let pedido = this.state.pedido;
				pedido.itens.splice(pedido.itens.findIndex((item) => item === rowData), 1);
				let firstItem = pedido.itens.length % 10 === 0 ? pedido.itens.length >= 10 ? pedido.itens.length - 10 : 0 : this.state.firstItem;
				this.setState({ pedido, alterado: true, firstItem });
			}} question="Tem certeza de que deseja remover este item?" />);
		} else {
			let pedido = this.state.pedido;
			pedido.itens.splice(pedido.itens.findIndex((item) => item === rowData), 1);
			let firstItem = pedido.itens.length % 10 === 0 ? pedido.itens.length >= 10 ? pedido.itens.length - 10 : 0 : this.state.firstItem;
			this.setState({ pedido, alterado: true, firstItem });
		}
	}

	handleUpdate = (key, event) => {
		let pedido = this.state.pedido;
		pedido[key] = event.target.value;
		this.setState({ pedido, alterado: true });
	}

	listTiposMovimento = (event) => {
		this.tipoMovimentoService.autoCompletar(event.query).then((listTiposMovimento) => this.setState({ listTiposMovimento }));
	}

	listCondicoesPagamento = (event) => {
		this.condicaoPagamentoService.autoCompletar(event.query).then((listCondicoesPagamento) => this.setState({ listCondicoesPagamento }));
	}


	listCfops = (event) => {
		this.cfopService.autoCompletar(event.query).then((listCfops) => this.setState({ listCfops }));
	}

	selectTabIndex = (index) => {
		this.setState({ selectedTab: index });
	}

	handleUpdateItem = (key, index, value) => {
		let pedido = this.state.pedido;
		pedido.itens[index][key] = value;
		if (pedido.sistema === 'TZM') {
			pedido.itens[index].percentualIpi = Number(pedido.itens[index].produto.percentualIpi);
			pedido.itens[index].valorIpi = (Number(pedido.itens[index].valorUnitario) * Number(pedido.itens[index].quantidade) * (Number(pedido.itens[index].percentualIpi)));
		}
		pedido.itens[index].valorTotalItem = Number(pedido.itens[index].valorUnitario) * Number(pedido.itens[index].quantidade);
		pedido.itens[index].valorTotal = (Number(pedido.itens[index].valorUnitario) * Number(pedido.itens[index].quantidade)) + Number(pedido.itens[index].valorIpi);
		this.setState({ pedido, alterado: true });
	}

	mountEnumOptions = (enumList) => {
		if (enumList != null) {
			let enums = [<option key={"vazioEnum"} value={null}></option>];
			enums.push(...enumList.map((value) => <option key={value.value} value={value.value}>{value.descriptor}</option>));
			return enums;
		}
		return null;
	}

	editorProduto = (rowData, props) => {
		return (
			<div className="tzm-ui-tablelike">
				<TZMAutoComplete inputClassName="editorProduto" field="descricao" itemTemplate={this.produtoTemplate} onKeyDown={this.blurOnEnter} value={this.state.pedido.itens[props.rowIndex].produto} onChange={(event) => {
					let pedido = this.state.pedido;
					pedido.itens[props.rowIndex].produto = event.value;
					this.setState({ pedido });
				}} suggestions={this.state.listProdutos} completeMethod={this.listProdutos} onSelect={(event) => {
					if (event.value && event.value.pk) {
						this.handleUpdateItem("produto", props.rowIndex, event.value);
						this.handleUpdateItem("unidade", props.rowIndex, event.value.unidade);
					}
				}} />
			</div>
		);
	}

	editorQuantidade = (rowData, props) => {
		return (
			<div className="tzm-ui-tablelike">
				<TZMTextField min={0} type="number" required value={this.state.pedido.itens[props.rowIndex].quantidade.toString()} onChange={(event) => this.handleUpdateItem("quantidade", props.rowIndex, event.target.value)} />
			</div>
		);
	}

	editorValorUnitario = (rowData, props) => {
		return (
			<div className="tzm-ui-tablelike">
				<TZMTextField min={0} type="number" required value={this.state.pedido.itens[props.rowIndex].valorUnitario.toString()} onChange={(event) => this.handleUpdateItem("valorUnitario", props.rowIndex, event.target.value)} />
			</div>
		);
	}

	editorCodigoTributario = (rowData, props) => {
		return (
			<div className="tzm-ui-tablelike">
				<TZMCombobox onKeyDown={(event) => {
					if (!event.shiftKey) {
						switch (event.keyCode) {
							case 9:
								if (props.rowIndex === this.state.pedido.itens.length - 1) {
									this.insertItem();
								}
								break;
							default:
						}
					}
				}} field="codigoTributario" name="codigoTributario" onChange={(event) => this.handleUpdateItem("codigoTributario", props.rowIndex, event.target.value)} value={this.state.pedido.itens[props.rowIndex].codigoTributario}>{this.codigosTributarios}</TZMCombobox>
			</div>
		);
	}

	editorTipoMovimento = (rowData, props) => {
		return (
			<div className="tzm-ui-tablelike">
				<TZMCombobox onKeyDown={(event) => {
					if (!event.shiftKey) {
						switch (event.keyCode) {
							case 9:
								if (props.rowIndex === this.state.pedido.itens.length - 1) {
									this.insertItem();
								}
								break;
							default:
						}
					}
				}} field="tipoMovimento" name="tipoMovimento" onChange={this.handleEntityCombobox} value={JSON.stringify(this.state.pedido.itens[props.rowIndex].tipoMovimento)}>
					{this.mountEnumOptions(this.state.tiposMovimento)}
				</TZMCombobox>
			</div>
		);
	}

	editorCfo = (rowData, props) => {
		return (
			<div className="tzm-ui-tablelike">
				<TZMCombobox field="cfo" name="cfo" onChange={this.handleEntityCombobox} value={JSON.stringify(this.state.pedido.itens[props.rowIndex].cfo)}>
					{this.mountEnumOptions(this.state.listCfops)}
				</TZMCombobox>
			</div>
		);
	}

	editorDescontoPercentual = (rowData, props) => {
		let descontoMaximo = this.state.pedido.itens[props.rowIndex].descontoMaximo ? this.state.pedido.itens[props.rowIndex].descontoMaximo : null;
		return (
			<div className="tzm-ui-tablelike">
				<TZMTextField type="number" required min={0} max={descontoMaximo} value={this.state.pedido.itens[props.rowIndex].descontoPercentual} onChange={(event) => {
					this.handleUpdateItem("descontoPercentual", props.rowIndex, event.target.value);
				}} onKeyDown={(event) => {
					if (!event.shiftKey) {
						switch (event.keyCode) {
							case 9:
								if (props.rowIndex === this.state.pedido.itens.length - 1) {
									this.insertItem();
								}
								break;
							default:
						}
					}
				}} />
			</div>
		);
	}

	listProdutos = (event) => {
		this.vwProdutoService.autoCompletar(event.query).then((listProdutos) => this.setState({ listProdutos }));
	}

	insertItem = () => {
		switch (this.state.selectedTab) {
			case 1:
				let pedido = this.state.pedido;
				if (!pedido.itens) {
					pedido.itens = [];
				}
				pedido.itens.push({ itemId: this.state.pedido.itens.map(i => i.itemId).reduce((a, b) => Math.max(a, b), 0) + 1, produto: { pk: {}, descricao: " ",  }, valorUnitario: 0, valorIpi: 0, percentualIpi: 0, valorTotal: 0, valorTotalItem: 0, quantidade: 0, desconto: 0, descontoPercentual: 0 });
				let firstItem = pedido.itens.length % 10 === 1 ? pedido.itens.length - 1 : this.state.firstItem;
				this.setState({ pedido, alterado: true, firstItem });
				if (firstItem === pedido.itens.length - 1) {
					setTimeout(() => {
						document.getElementsByClassName("editorProduto")[0].focus();
					}, 200);
				}
				break;
			default:
		}
	}

	handleCliente = (key, value) => {
		let pedido = this.state.pedido;
		if (value) {
			pedido.cliente[key] = JSON.parse(value);
		} else {
			pedido.cliente[key] = {};
		}
		this.setState({ pedido, alterado: true });
	}

	enderecoLiteral(endereco) {
		return "[" + endereco.pk.tipo + "] " + endereco.logradouro + " " + endereco.bairro + ". CEP " + endereco.cep + " - " + endereco.cidade + "/" + endereco.estado;
	}

	confirmarPedido = () => {
		if (this.validarPedido()) {
			let messages = [];
			if (this.state.pedido.cliente.posicao === "INATIVO") {
				messages.push("Não é possível confirmar pedidos para clientes inativos.");
			}
			if (this.state.pedido.vendedor.posicao === "INATIVO") {
				messages.push("Não é possível confirmar pedidos para vendedores inativos.");
			}

			if (this.state.pedido.condicaoPagamento.especial && this.state.pedido.adiantamento === 0) {
				messages.push("Valor do adiantamento não informado.");
			}

			if (this.state.pedido.adiantamento > 0) {
				if (Validator.isEmpty(this.state.pedido.imagem.id) && Validator.isEmpty(this.state.pedido.imagem.conteudo)) {
					messages.push("A imagem do comprovante de adiantamento é obrigatória");
				}
				if (!Validator.isEmail(this.state.pedido.emailAdiantamento)) {
					messages.push("O email para envio do comprovante de adiantamento é obrigatório");
				} else if (!this.state.pedido.emailAdiantamento.toLowerCase().endsWith("@beontag.com")) {
					messages.push("O email para envio do comprovante de adiantamento deve pertencer ao domínio da BeOnTag");
				}
				if (Validator.isEmpty(this.state.pedido.textoAdiantamento)) {
					messages.push("O texto para envio do comprovante de adiantamento é obrigatório");
				}
			}
			if (messages.length > 0) {
				ModalRealm.showDialog(<TZMPopup key={TZMUserData.getIdGenerator()} header="Advertência" messages={messages} />);
			} else {
				ModalRealm.showDialog(<TZMConfirm question="Tem certeza de que deseja confirmar este pedido? Após confirmá-lo não será mais possível alterá-lo pelo portal. Qualquer alteração deverá ser realizada no Omega." onYes={() => {
					let pedido = this.state.pedido;
					pedido.novo = true;
					pedido.status = "CONFIRMADO";
					this.setState({ pedido });
					this.salvarPedido();
				}} />);
			}

		}
	}

	motivoBloqueio = (rowData) => {
		ModalRealm.showDialog(<MotivoBloqueio key={TZMUserData.getIdGenerator()} logs={rowData.motivosBloqueio} />);
	}

	queryAutoComplete = (event) => {
		let pedido = this.state.pedido;
		pedido[event.name] = event.value;
		this.setState({ pedido });
	}

	selectAutoComplete = (event) => {
		let pedido = this.state.pedido;
		pedido[event.name] = event.value;
		this.setState({ pedido, alterado: true });
	}

	clearAutoComplete = (event) => {
		let pedido = this.state.pedido;
		pedido[event.name] = { pk: {}, [event.field]: " " };
		this.setState({ pedido, alterado: true });
	}

	clearAutoCompleteCliente = (event) => {
		let pedido = this.state.pedido;
		pedido[event.name] = { pk: {}, [event.field]: " " };
		if ((pedido.tipoMovimento === null) || (pedido.tipoMovimento != null && !pedido.tipoMovimento.bonificacao)) {
			pedido.condicaoPagamento = { pk: {}, descricao: " ", descricaoAmigavel: " " };
		}
		this.setState({ pedido, alterado: true });
	}

	checkNullArray(value) {
		return value ? value : [];
	}

	handleChange = (event) => {
		let pedido = this.state.pedido;
		pedido[event.name][event.field] = event.target.value;
		this.setState({ pedido, alterado: true });
	}

	handleCChange = (event) => {
		let pedido = this.state.pedido;
		pedido[event.name] = event.value;
		this.setState({ pedido });
	}

	handleEntityCombobox = (event) => {

		let pedido = this.state.pedido;

		if (event.target.value != null && event.target.value !== "") {
			pedido[event.name] = JSON.parse(event.target.value);
		} else {
			pedido[event.name] = null;
		}
		this.setState({ pedido, alterado: true });

	}

	handleEntityComboboxTipoMovimento = (event) => {

		let pedido = this.state.pedido;
		if (event.target.value != null && event.target.value !== "") {
			pedido[event.name] = JSON.parse(event.target.value);
			if (pedido[event.name].bonificacao) {
				this.condicaoPagamentoService.bonificacao('2730').then((bonificacao => {
					pedido.condicaoPagamento = bonificacao;
					this.setState({ pedido });
				}));
			} else {
				pedido.condicaoPagamento = pedido.cliente.condicaoPagamento;
			}
		} else {
			pedido.condicaoPagamento = pedido.cliente.condicaoPagamento
			pedido[event.name] = null;
		}
		this.setState({ pedido, alterado: true });

	}

	handleCombobox = (event) => {
		let pedido = this.state.pedido;
		pedido[event.name] = event.target.value;
		this.setState({ pedido, alterado: true });
	}

	blurOnEnter = (event) => {
		if (event.keyCode === 9 || event.keyCode === 13) document.activeElement.blur();
	}

	formasPagamento = [
		<option key={-1} value={null}></option>,
		<option key={0} value="DINHEIRO">Dinheiro</option>,
		<option key={1} value="BOLETO_BANCARIO">Boleto Bancário</option>,
		<option key={2} value="OUTROS">Outros</option>
	];

	pagamentosFrete = [
		<option key={0} value={null}></option>,
		<option key={'P'} value="FOB">FOB - Frete a Pagar</option>,
		<option key={'C'} value="CIF">CIF - Frete Incluso</option>
	];

	codigosTributarios = [
		<option key={-1} value={null}></option>,
		<option key={51} value={51}>51</option>,
		<option key={100} value={100}>100</option>,
		<option key={600} value={600}>600</option>,
		<option key={890} value={890}>890</option>,
		<option key={990} value={990}>990</option>,
		<option key={8090} value={8090}>8090</option>,
		<option key={9090} value={9090}>9090</option>,
		<option key={590} value={590}>590</option>,
		<option key={690} value={690}>690</option>,
		<option key={251} value={251}>251</option>,
		<option key={500} value={500}>500</option>,
		<option key={241} value={241}>241</option>,
		<option key={200} value={200}>200</option>,
		<option key={400} value={400}>400</option>,
		<option key={300} value={300}>300</option>,
		<option key={341} value={341}>341</option>,
		<option key={140} value={140}>140</option>,
		<option key={700} value={700}>700</option>,
		<option key={390} value={390}>390</option>,
		<option key={220} value={220}>220</option>,
		<option key={320} value={320}>320</option>,
		<option key={1041} value={1041}>1041</option>

	].sort((a, b) => a.props.value - b.props.value);

	produtoTemplate(produto) {
		return `${produto.pk.produtoId} - ${produto.descricao}`;
	}

	clienteTemplate(cliente) {
		return <div><i className={`fa fa-${cliente.posicao === "ATIVO" ? "check-circle" : "minus-circle"}`} /> {cliente.pk.clienteId} - {cliente.razaoSocial}</div>;
	}

	vendedorTemplate(vendedor) {
		return <div><i className={`fa fa-${vendedor.posicao === "ATIVO" ? "check-circle" : "minus-circle"}`} /> {vendedor.pk.vendedorId} - {vendedor.nome}</div>;
	}

	cfopTemplate(cfop) {
		return `${cfop.pk.cfopId} - ${cfop.descricao}`;
	}

	transportadoraTemplate(transportadora) {
		return `${transportadora.descricaoAmigavel} (${transportadora.localidade})`;
	}

	condicaoPagamentoTemplate(condicaoPagamento) {
		return `${condicaoPagamento.pk.condicaoPagamentoId} - ${condicaoPagamento.descricao}`;
	}

	loadImage = (event) => {
		let reader = new FileReader();
		if (this.input.files != null && this.input.files.length > 0) {
			reader.readAsDataURL(this.input.files[0]);
			reader.onload = (event) => {
				let pedido = this.state.pedido;
				pedido.imagem = { conteudo: event.target.result };
				this.setState({ pedido });
			};
		}
	}

	fileInputRef = (input) => {
		this.input = input;
	}

	paginacaoItens = (event) => {
		this.setState({ firstItem: event.first });
	}

	editarItemPedido = (itemPedido) => {
		ModalRealm.showDialog(<EditarItemPedido key={TZMUserData.getIdGenerator()} itemPedido={itemPedido} onSalvar={(itemPedido) => {
			let pedido = this.state.pedido;
			pedido.itens.filter(i => i.itemId === itemPedido.itemId).forEach(item => Object.assign(item, itemPedido));
			this.setState({ pedido });
		}} tiposMovimento={this.state.tiposMovimento} />);
	}

	statusEnvioEmail = () => {
		if (!this.state.logEmail) return null;
		let mensagem;
		switch (this.state.logEmail.status) {
			case "SUCESSO": mensagem = "Email enviado com sucesso!"; break;
			default: mensagem = "Falha ao enviar email"; break;
		}
		return <div className={`ui-tzm-email-chip ui-tzm-email-chip-${this.state.logEmail.status === "SUCESSO" ? "success" : "failure"}`}>{mensagem}</div>;
	}

	columnStatus = (pedido) => {
		let value;
		switch (pedido.status) {
			case "BLOQUEADO":
				value = "lock";
				break;
			case "CANCELADO":
				value = "ban";
				break;
			case "CARTEIRA":
				value = "wallet";
				break;
			case "FATURADO":
				value = "check";
				break;
			case "ERRO":
				value = "times";
				break;
			case "RASCUNHO":
				value = "shopping-cart";
				break;
			case "PROCESSADO":
				value = "thumbs-up";
				break;
			default:
				value = "clock";
				break;
		}
		return `fas fa-${value}`
	}



	render() {
		let exportacao = this.state.pedido.cliente.exportacao ? null : "none";
		let adiantamento = Number(this.state.pedido.adiantamento) > 0 ? null : "none";
		let display = TZMUserData.isRepresentante()  ? "none" : null;
		return (
			<TZMDialog style={{ width: "1300px" }} visible={this.state.visible} modal header={
				<span className="fake-link">Pedido {this.state.pedido.pk && this.state.pedido.pk.pedidoId ? this.state.pedido.pk.pedidoId : ""}
					<span style={{ position: "absolute", right: "40px", top: "8px" }}>
						<i style={{ marginRight: "8px" }} className={this.columnStatus(this.state.pedido)} />{this.state.pedido.status}</span>
				</span>
			} {...this.props}  >
				<TZMTabView onTabChange={(event) => this.setState({ selectedTab: event.index })} activeIndex={this.state.selectedTab}>
					<TZMTabPanel header={<span><span className="ui-tab-number" children="1" />Dados Principais</span>}>
						<div className="ui-g">
							<div className="ui-g-2"><TZMTextField maxLength={15} onChange={(event) => this.handleUpdate("seuPedido", event)} label="Seu Pedido" value={this.state.pedido.seuPedido} /></div>
							<div className="ui-g-2"><TZMTextField maxLength={15} onChange={(event) => this.handleUpdate("pedidoCliente", event)} label="Pedido Cliente" value={this.state.pedido.pedidoCliente} /></div>
							<div className="ui-g-2"><TZMCalendar appendTo={document.body} onChange={(event) => this.handleUpdate("dataPedido", event)} label="Data do Pedido" value={moment(this.state.pedido.dataPedido).format("YYYY-MM-DD")} /></div>
							<div className="ui-g-2"><TZMCalendar appendTo={document.body} onChange={(event) => this.handleUpdate("dataPedidoCliente", event)} label="Data do Cliente" value={moment(this.state.pedido.dataPedidoCliente).format("YYYY-MM-DD")} /></div>
							<div className="ui-g-2"><TZMCalendar appendTo={document.body} onChange={(event) => this.handleUpdate("dataEntrega", event)} label="Data Prevista para Entrega" value={this.state.pedido.dataEntrega} /></div>
							<div className="ui-g-2"><TZMCalendar appendTo={document.body} onChange={(event) => this.handleUpdate("dataPrevisaoFaturamento", event)} label="Prevista para Faturamento" value={moment(this.state.pedido.dataPrevisaoFaturamento).format("YYYY-MM-DD")} /></div>
							<div className="ui-g-2"><TZMCalendar appendTo={document.body} onChange={(event) => this.handleUpdate("dataMinimaFaturamento", event)} label="Mínima para Faturamento" value={moment(this.state.pedido.dataMinimaFaturamento).format("YYYY-MM-DD")} /></div>
							<div className="ui-g-10" />
							<div className="ui-g-1"><TZMTextField disabled forac="true" value={this.state.pedido.cliente.posicao} label="Situação" /></div>
							<div className="ui-g-4" style={{ position: "relative" }}>
								{Validator.isEntidade(this.state.pedido.cliente) && this.state.pedido.cliente.pk.clienteId ? <div className="tzm-overlink" onClick={() => this.setState({ maisInfoCliente: !this.state.maisInfoCliente })}>{this.state.maisInfoCliente ? "Menos" : "Mais"} informações</div> : null}
								<TZMAutoComplete emptyMessage="Nenhum registro encontrado" placeholder="Razão social, fantasia ou CNPJ" itemTemplate={this.clienteTemplate} onClear={this.clearAutoCompleteCliente} onSelect={this.selectCliente} onChange={this.queryAutoComplete} suggestions={this.state.listClientes} completeMethod={this.listClientes} label="Cliente" name="cliente" field="descricaoAmigavel" value={this.state.pedido.cliente} />
							</div>
							<div className="ui-g-4"><TZMTextField disabled forac="true" label="Nome Fantasia" value={this.state.pedido.cliente.nomeFantasia} /></div>
							<div className="ui-g-3"><TZMTextField disabled forac="true" label="E-mail" value={this.state.pedido.cliente.email} /></div>
							{this.state.maisInfoCliente ? (
								<div>
									<div className="ui-g-3"><TZMTextField disabled forac="true" label="CPF/CNPJ" value={this.state.pedido.cliente.cpfCnpj} /></div>
									<div className="ui-g-3"><TZMTextField disabled forac="true" label="Inscrição Estadual" value={this.state.pedido.cliente.inscricaoEstadual} /></div>
									<div className="ui-g-2"><TZMTextField disabled forac="true" label="Telefone 1" value={this.state.pedido.cliente.telefone1} /></div>
									<div className="ui-g-2"><TZMTextField disabled forac="true" label="Telefone 2" value={this.state.pedido.cliente.telefone2} /></div>
									<div className="ui-g-2"><TZMTextField disabled forac="true" label="Telefone 3" value={this.state.pedido.cliente.telefone3} /></div>
									<div className="ui-g-12">
										<TZMTable value={this.state.pedido.cliente.contatos} emptyMessage="Nenhum contato encontrado">
											<TZMColumn key="nome" style={{ textAlign: "left", width: "15em" }} field="nome" header="Contato" />
											<TZMColumn key="telefone1" style={{ textAlign: "center", width: "10em" }} field="telefone1" header="Telefone 1" />
											<TZMColumn key="telefone2" style={{ textAlign: "center", width: "10em" }} field="telefone2" header="Telefone 2" />
											<TZMColumn key="email" style={{ width: "*" }} field="email" header="E-mail" body={(rowData) => rowData != null ? asEmail(rowData.email) : null} />
											<TZMColumn key="observacao" style={{ width: "6em" }} field="observacao" header="Observação" body={(rowData) => {
												let id = rowData.nome + "_" + Math.round(Math.random() * 1000000) + "_id";
												return (
													<div id={id} style={{ textAlign: "center" }}>
														<span className="fa fa-info" />
														<Tooltip for={`#${id}`} title={rowData.observacao} tooltipPosition="top" />
													</div>
												);
											}} />
										</TZMTable>
									</div>
								</div>
							) : null}
							<div className="ui-g-6" style={{ position: "relative" }}>
								<TZMCombobox label="Endereço de Cobrança" value={JSON.stringify(this.state.pedido.cliente.enderecoCobranca)} onChange={(event) => this.handleCliente("enderecoCobranca", event.target.value)}>
									{[<option key={"vazioaEndCobranca"} value={null}>Nenhum endereço selecionado</option>, ...this.checkNullArray(this.state.pedido.cliente.enderecos).map((endereco) => <option key={endereco} value={JSON.stringify(endereco)}>{this.enderecoLiteral(endereco)}</option>)]}
								</TZMCombobox>
								{this.state.pedido.cliente.enderecoCobranca ? this.state.pedido.cliente.enderecoCobranca.pk ? <div className="tzm-tag-combobox">{this.state.pedido.cliente.enderecoCobranca.pk.tipo}</div> : null : null}
							</div>
							<div className="ui-g-6" style={{ position: "relative" }}>
								<TZMCombobox label="Endereço de Entrega" value={JSON.stringify(this.state.pedido.cliente.enderecoEntrega)} onChange={(event) => this.handleCliente("enderecoEntrega", event.target.value)}>
									{[<option key={"vazioEndEntrega"} value={null}>Nenhum endereço selecionado</option>, ...this.checkNullArray(this.state.pedido.cliente.enderecos).map((endereco) => <option key={endereco} value={JSON.stringify(endereco)}>{this.enderecoLiteral(endereco)}</option>)]}
								</TZMCombobox>
								{this.state.pedido.cliente.enderecoEntrega ? this.state.pedido.cliente.enderecoEntrega.pk ? <div className="tzm-tag-combobox">{this.state.pedido.cliente.enderecoEntrega.pk.tipo}</div> : null : null}
							</div>
							<div className="ui-g-3"><TZMCombobox field="descricao" name="tipoMovimento" label="Tipo de Movimento" onChange={this.handleEntityComboboxTipoMovimento} value={JSON.stringify(this.state.pedido.tipoMovimento)}>{this.mountEnumOptions(this.state.tiposMovimento)}</TZMCombobox></div>
							<div className="ui-g-4"><TZMAutoComplete minLength={1} onClear={this.clearAutoComplete} onSelect={this.selectAutoComplete} itemTemplate={this.cfopTemplate} onChange={this.queryAutoComplete} suggestions={this.state.listCfops} 
							 placeholder="Código ou descrição" field="descricaoAmigavel" name="cfop" completeMethod={this.listCfops} label="CFOP" value={this.state.pedido.cfop} /></div>
							<div className="ui-g-3"><TZMAutoComplete onChange={this.queryAutoComplete} itemTemplate={this.condicaoPagamentoTemplate} onSelect={this.selectAutoComplete} field="descricaoAmigavel" disabled={true} onClear={this.clearAutoComplete} suggestions={this.state.listCondicoesPagamento} name="condicaoPagamento" completeMethod={this.listCondicoesPagamento} label="Condição de Pagamento" value={this.state.pedido.condicaoPagamento} /></div>
							<div className="ui-g-2"><TZMCombobox label="Forma de Pagamento" name="formaPagamento" children={this.formasPagamento} onChange={this.handleCombobox} value={this.state.pedido.formaPagamento} /></div>
							<div className="ui-g-4"><TZMAutoComplete itemTemplate={this.transportadoraTemplate} onClear={this.clearAutoComplete} onSelect={this.selectAutoComplete} onChange={this.queryAutoComplete} placeholder="Razão social, fantasia ou CNPJ"
													suggestions={this.state.listTransportadoras} completeMethod={this.listTransportadoras} name="transportadora" label="Transportadora" value={this.state.pedido.transportadora} field="descricaoAmigavel" /></div>
							<div className="ui-g-2"><TZMTextField disabled forac="true" label="Nome Fantasia" value={this.state.pedido.transportadora ? this.state.pedido.transportadora.nomeFantasia : null} /></div>
							<div className="ui-g-2"><TZMTextField disabled forac="true" label="CPF/CNPJ" value={this.state.pedido.transportadora ? this.state.pedido.transportadora.cpfCnpj : null} /></div>
							<div className="ui-g-2"><TZMCombobox name="frete" label="Pagamento do Frete" onChange={this.handleCombobox} value={this.state.pedido.frete}>{this.pagamentosFrete}</TZMCombobox></div>
							<div className="ui-g-2"><TZMTextField type="number" label="Valor do Frete" onChange={(event) => {
								let pedido = this.state.pedido;
								pedido.valorFrete = Number(event.target.value);
								this.setState({ pedido });
							}} value={this.state.pedido.valorFrete.toString()} /></div>
							<div className="ui-g-4"><TZMAutoComplete itemTemplate={this.transportadoraTemplate} onClear={this.clearAutoComplete} onSelect={this.selectAutoComplete} onChange={this.queryAutoComplete} placeholder="Razão social, fantasia ou CNPJ"
							suggestions={this.state.listTransportadorasRedespacho} completeMethod={this.listTransportadorasRedespacho} name="transportadoraRedespacho" label="Transportadora Redespacho" value={this.state.pedido.transportadoraRedespacho} field="descricaoAmigavel" /></div>
							<div className="ui-g-2"><TZMTextField disabled forac="true" label="Nome Fantasia" value={this.state.pedido.transportadoraRedespacho ? this.state.pedido.transportadoraRedespacho.nomeFantasia : null} /></div>
							<div className="ui-g-2"><TZMTextField disabled forac="true" label="CPF/CNPJ" value={this.state.pedido.transportadoraRedespacho ? this.state.pedido.transportadoraRedespacho.cpfCnpj : null} /></div>
							<div className="ui-g-2"><TZMCurrency label="Valor do Adiantamento" className="ui-textfield-group ui-textfield" name="adiantamento" prefix="R$" value={this.state.pedido.adiantamento} onChange={this.handleCChange} /></div>
						</div>
					</TZMTabPanel>

					<TZMTabPanel header={<span><span className="ui-tab-number" children="2" />Itens do Pedido</span>}>
						<div className="ui-g">
							<div className="ui-g-12">
								<TZMTable selection={this.state.selectedItemPedido} onPage={this.paginacaoItens} first={this.state.firstItem} value={this.state.pedido.itens} paginator rows={10} paginatorLeft={
									this.state.pedido.sistema === 'TZM' ?
										<div style={{ textAlign: "left" }}>
											<Button icon="fas fa-plus" title="Adicionar novo item" className="tzm-paginator-button" onClick={this.insertItem} />
										</div> : null

								} emptyMessage="Nenhum item adicionado" footer={<div>Valor total: {this.state.pedido.itens.map((item) => item.valorTotalItem).reduce((a, b) => a + b, 0).toCurrency()}</div>}>
									<TZMColumn key="statusItem"  style={{ width: "2em", textAlign: "center" }} header="" body={IntegracaoService.columnStatus} />
									<TZMColumn key="item" style={{ width: "3em", textAlign: "center" }} header="Item" body={(item, column) => this.state.pedido.sistema === 'OMEGA' ? item.pk.itemId : column.rowIndex + 1} />
									<TZMColumn key="codigo" style={{ textAlign: "center", width: "10em" }} header="Cod. Produto" body={(itemPedido) => Validator.isEntidade(itemPedido.produto) ? itemPedido.produto.pk.produtoId : ""} />
									<TZMColumn key="descricao" body={this.editorProduto} style={{ width: "*" }} header="Produto" />
									<TZMColumn key="quantidade" body={this.editorQuantidade} style={{ width: "7.5em" }} field="quantidade" header="Quantidade" />
									<TZMColumn key="unidade" field="unidade" style={{ textAlign: "center", width: "4.5em" }} header="Unidade" />
									<TZMColumn key="valorUnitario" style={{ width: "7em" }} name="valorUnitario" field="valorUnitario" header="Valor Unitário" body={this.editorValorUnitario} />
									<TZMColumn key="valorTotalItem" style={{ width: "8em" }} field="valorTotalItem" header="Valor Total (S/ Imposto)" body={(rowData) => {
										return <div style={{ textAlign: "right" }}>{toCurrency(rowData["valorTotalItem"])}</div>;
									}} />
									<TZMColumn key="valorIpi" style={{ width: "8em" }} header="Valor IPI" body={(rowData) => {
										return <div title={`Percentual: ` + Number(rowData["percentualIpi"]) * 100 + `%`} style={{ textAlign: "right" }}>{rowData["valorIpi"].toCurrency()}</div>;
									}} />
									{/* <TZMColumn key="percentualIcms" style={{ width: "3em" }} field="percentualIcms" header="ICMS %" body={this.percentFormat} /> */}

									<TZMColumn key="valorTotal" style={{ width: "8em" }} header="Valor Total (C/ Imposto)" body={(rowData) => {
										return <div style={{ textAlign: "right" }}>{rowData["valorTotal"].toCurrency()}</div>;
									}} />
									<TZMColumn key="codigoTributario" style={{ width: "7em" }} header="Cód. Tributário" body={this.editorCodigoTributario} />


									<TZMColumn style={{ width: "6em", textAlign: "center" }} key="acoes" header="Ações" body={(rowData) => {
										return (
											<div>
												{rowData.id ? (
													<span className="fake-link" onClick={(event) => {
														this.integracaoService.logsItemPedido(this.state.pedido.pedidoId, rowData.itemId).then((logs) => ModalRealm.showDialog(<LogIntegracao logs={logs} />));
													}}><TZMIcon title="Visualizar Registros de Integração" className="ui-action-icon" name="database" /></span>
												) : null}
												{<span className="fake-link" onClick={() => this.editarItemPedido(rowData)}><TZMIcon title="CFOP / Tipo Movimento" className="ui-action-icon ui-action-icon-edit" name="edit" /></span>}
												{this.state.editavel ? <span className="fake-link" onClick={(event) => this.deleteItemPedido(event, rowData)}><TZMIcon title="Excluir Item do Pedido" className="ui-action-icon ui-action-icon-delete" name="trash-alt" /></span> : null}
											</div>
										);
									}} />
								</TZMTable>
							</div>
						</div>
					</TZMTabPanel>
					<TZMTabPanel headerStyle={{ display }} header={<span><span className="ui-tab-number" children="3" />Equipe de Vendas</span>}>
						<div className="ui-g">
							<div className="ui-g-1"><TZMTextField disabled forac="true" value={this.state.pedido.vendedor.posicao} label="Situação" /></div>
							<div className="ui-g-5"><TZMAutoComplete itemTemplate={this.vendedorTemplate} onClear={this.clearAutoComplete} onSelect={this.selectVendedores} onChange={this.queryAutoComplete} suggestions={this.state.listVendedores} completeMethod={this.listVendedores} name="vendedor" field="descricaoAmigavel" label="Vendedor" value={this.state.pedido.vendedor} /></div>
							<div className="ui-g-6"><TZMTextField disabled forac="true" label="Nome Conhecido" value={this.state.pedido.vendedor.apelido} /></div>
							<div className="ui-g-3"><TZMTextField disabled forac="true" label="CPF" value={this.state.pedido.vendedor.cpfcnpj} /></div>
							<div className="ui-g-2"><TZMTextField disabled forac="true" label="Celular" value={this.state.pedido.vendedor.celular} /></div>
							<div className="ui-g-2"><TZMTextField disabled forac="true" label="Telefone" value={this.state.pedido.vendedor.telefone} /></div>
							<div className="ui-g-5"><TZMTextField disabled forac="true" label="E-mail" value={this.state.pedido.vendedor.email} /></div>
							<div className="ui-g-1"><TZMTextField disabled forac label="Supervisor" value={this.state.pedido.supervisor ? this.state.pedido.supervisor.pk.vendedorId : null} /></div>
							<div className="ui-g-5"><TZMTextField disabled forac label="Nome" value={this.state.pedido.supervisor ? this.state.pedido.supervisor.nome : null} /></div>
							<div className="ui-g-1"><TZMTextField disabled forac label="Gerente"  value={this.state.pedido.gerente ? this.state.pedido.gerente.pk.vendedorId : null}/></div>
							<div className="ui-g-5"><TZMTextField disabled forac label="Nome" value={this.state.pedido.gerente ? this.state.pedido.gerente.nome : null} /></div>
						</div>
					</TZMTabPanel>
					<TZMTabPanel header={<span><span className="ui-tab-number" children="4" />Textos</span>}>
						<div className="ui-g">
							<div className="ui-g-6"><TZMTextArea name="textoInterno" field="texto" onChange={(event) => {
								let pedido = this.state.pedido;
								pedido.textoInterno[0].alterado = true;
								pedido.textoInterno[0].texto = event.target.value.replace(/(^\s+|\s+$)/g, ' ');
								this.setState({ pedido });
							}} maxLength="4000" style={{ height: "110px" }} label="Texto Interno" value={this.state.pedido.textoInterno != null && this.state.pedido.textoInterno.length > 0 ? this.state.pedido.textoInterno[0].texto : null} /></div>
							<div className="ui-g-6"><TZMTextArea name="textoEspecifico" field="texto" onChange={(event) => {
								let pedido = this.state.pedido;
								pedido.textoEspecifico[0].alterado = true;
								pedido.textoEspecifico[0].texto = event.target.value.replace(/(^\s+|\s+$)/g, ' ');
								this.setState({ pedido });
							}} maxLength="4000" style={{ height: "110px" }} label="Texto Específico" value={this.state.pedido.textoEspecifico != null && this.state.pedido.textoEspecifico.length > 0 ? this.state.pedido.textoEspecifico[0].texto : null} /></div>
						</div>
					</TZMTabPanel>
					<TZMTabPanel headerStyle={{ display: exportacao }} header={<span><span className="ui-tab-number" children="5" />Exportação</span>}>
						<div className="ui-g">
							<div className="ui-g-4"><TZMTextField maxLength={35} onChange={(event) => this.handleUpdate("localidadeEmbarque", event)} label="Local de Embarque" value={this.state.pedido.localidadeEmbarque} /></div>
							<div className="ui-g-1"><TZMCombobox maxLength={2} onChange={this.handleCombobox} children={UF.list} name="ufEmbarque" label="UF de Embarque" value={this.state.pedido.ufEmbarque} /></div>
							<div className="ui-g-3"><TZMTextField maxLength={35} onChange={(event) => this.handleUpdate("ordemVenda", event)} label="Ordem de Venda" value={this.state.pedido.ordemVenda} /></div>
							<div className="ui-g-4"><TZMCombobox label="Índice da Moeda de Exportação" onChange={this.handleEntityCombobox} children={this.state.indices} name="indice" value={JSON.stringify(this.state.pedido.indice)} /></div>
						</div>
					</TZMTabPanel>
					<TZMTabPanel headerStyle={{ display: adiantamento }} header={<span><span className="ui-tab-number" children="6" />Comprovante de Adiantamento</span>}>
						<div className="ui-g">
							<div className="ui-g-6" style={{ position: "relative" }}>
								<label>Imagem do Comprovante</label>
								<img className="ui-image-load" src={this.state.pedido.imagem != null ? this.state.pedido.imagem.conteudo != null ? this.state.pedido.imagem.conteudo : `${basePath}/public/imagens/${this.state.pedido.imagem.id}.jpg` : null} alt="" />
								<input className="ui-file-load" ref={this.fileInputRef} type="file" onChange={this.loadImage} />
							</div>
							<div className="ui-g-6">
								<div className="ui-g">
									<div className="ui-g-8 ui-g-nopad"><TZMTextField label="Email do Contato" onChange={(event) => this.handleUpdate("emailAdiantamento", event)} value={this.state.pedido.emailAdiantamento} /></div>
									<div className="ui-g-4 ui-g-nopad">{this.statusEnvioEmail()}</div>
									<div className="ui-g-12 ui-g-nopad" style={{ marginTop: "7px" }}><TZMTextArea onChange={(event) => this.handleUpdate("textoAdiantamento", event)} value={this.state.pedido.textoAdiantamento} style={{ height: "360px" }} label="Observações do Adiantamento" /></div>
								</div>
							</div>
						</div>
					</TZMTabPanel>
				</TZMTabView>
				<TZMPanelFooter>
					{this.state.editavel ? <TZMButton className="ui-button-success" style={{ float: "left" }} label="Confirmar" icon="fas fa-check-circle" onClick={this.confirmarPedido} /> : null}
					{this.state.editavel ? <TZMButton className="ui-button-primary" label="Salvar" icon="fas fa-save" onClick={this.salvarPedido} /> : null}
					{!this.state.editavel && (this.state.pedido.motivosBloqueio != null && this.state.pedido.motivosBloqueio.length > 0) ? <TZMButton className="ui-button-warning" icon="fas fa-shield-alt" style={{ float: "left" }} label="Bloqueios" onClick={ ()  => this.motivoBloqueio(this.state.pedido)} /> : null}
					<TZMButton className="ui-button-secondary" label="Fechar" icon="fas fa-close" onClick={this.fecharPedido} />
				</TZMPanelFooter>
				<TZMShortcut active={this.state.visible}
					onCtrlS={() => this.state.editavel ? this.salvarPedido() : null}
					onEscape={this.fecharPedido}
					onInsert={this.insertItem}
					onAlt1={() => this.selectTabIndex(0)}
					onAlt2={() => this.selectTabIndex(1)}
					onAlt3={() => this.selectTabIndex(2)}
					onAlt4={() => this.selectTabIndex(3)}
					onAlt5={() => this.selectTabIndex(4)}
					onAlt6={() => this.selectTabIndex(5)}
				/>
			</TZMDialog>

		);

	}

}
 