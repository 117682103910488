import React from "react";
import { App } from "./App";
import { ModalRealm } from "./components/modal/ModalRealm";
import Login from "./pages/comum/login/Login";
import Usuario from "./pages/comum/usuario/Usuario";
import { Fetch } from "./utilities/Fetch";
import TZMRecover from "./utilities/TZMRecover";
import TZMUserData from "./utilities/TZMUserData";

const UserContext = React.createContext({});
const ambiente = process.env.REACT_APP_AMBIENTE;

export class Application extends React.Component {
	
	state = {
		usuario: new Usuario(),
		loading: true
	};

	componentWillMount() {
		Fetch.Get("/eu").then(response => {
			if (response.status === 200) {
				response.text().then(result => {
					if (result) {
						let usuario = JSON.parse(result);
						TZMUserData.setUsuario(usuario);
						let pedidos = "/pedidosRFID";
						if ((window.location.pathname === "/" ) || window.location.pathname === "/login") {
							if (ambiente === "INTERNO") {
								if (TZMUserData.isAdministrador() || TZMUserData.isDiretor()) {
									window.location.replace("/painelVolume");
								} else {
									window.location.replace(pedidos);
								}
							} else {
								window.location.replace(pedidos);
							}
						}
						this.setState({usuario, loading: false});
					} else {
						this.setState({loading: false});
					}
				});
			} else {
				if (response.status === 403) {
					window.sessionStorage.removeItem("X-User-Content");
				}
				this.setState({loading: false});
			}
		}).catch(() => {
			window.sessionStorage.removeItem("X-User-Content");
			this.setState({loading: false});
			ModalRealm.showDialog(
				<TZMRecover key={TZMUserData.getIdGenerator()} />
			);
		});
	}

	render() {
		if (this.state.loading) {
			return null;
		} else {
			if (this.state.usuario.id) {
				return (
					<UserContext.Provider value={this.state.usuario}>
						<App />
					</UserContext.Provider>
				);
			} else {
				return <Login />;
			}
		}
	}
	
}
