import moment from 'moment';
import 'moment/min/locales';
import React from "react";
import TZMButton from "../../../components/common/TZMButton";
import TZMForm from "../../../components/common/TZMForm";
import { toDecimal } from '../../../components/common/TZMFormatter';
import TZMTextField from "../../../components/common/TZMTextField";
import TZMConfirm from '../../../components/dialog/TZMConfirm';
import TZMMainContent from "../../../components/main/TZMMainContent";
import { ModalRealm } from '../../../components/modal/ModalRealm';
import TZMPanel from "../../../components/panel/TZMPanel";
import TZMPanelFooter from "../../../components/panel/TZMPanelFooter";
import TZMColumn from "../../../components/table/TZMColumn";
import TZMTable from "../../../components/table/TZMTable";
import { Fetch } from '../../../utilities/Fetch';
import TZMShortcut from "../../../utilities/TZMShortcut";
import TZMUserData from "../../../utilities/TZMUserData";
import { MetaService } from "../../servicos/MetaService";
import EditarMeta from "./EditarMeta";

const title = "Metas";
const empresaNome = process.env.REACT_APP_EMPRESA_NOME;

export default class Meta extends React.Component {

	constructor() {
		super();
		this.state = {
			list: [], 
			data: {},
			params: {
				meta: undefined,
				page: 0,
				size: 10000,
				sort: "mes"
			}
		};			
		this.metaService = new MetaService();
		moment.locale('pt-BR');
	}

	componentDidMount() {
		if (TZMUserData.isAutenticado()) {
			Fetch.Get("/ping");
		} else {
			window.location.replace("/");		
		}
	}

	mesFormat(rowData, column) {
		let mes = moment(rowData[column.field]).format("MMMM  [-] YYYY");
		mes = mes.charAt(0).toUpperCase() + mes.slice(1);
		return mes;
	} 

	handleList = () => {
		this.metaService.listar(this.state.params).then((data) => this.setState({list: data}));
	}	

	selectMeta = (event) => {
		this.setState({selectedMeta: event.data});
		if (event.data != null) {
			if (event.data.id != null) {
				this.metaService.completar(event.data).then((meta) => {
					ModalRealm.showDialog(<EditarMeta meta={meta} onClose={this.handleList} onDelete={this.handleList} onSave={this.handleList} />);
				});
			} else {
				ModalRealm.showDialog(<EditarMeta meta={event.data} onClose={this.handleList} onDelete={this.handleList} onSave={this.handleList} />);
			}
		}
	}

	newMeta = () => {
		if (TZMUserData.hasRole("____META_C")) {
			this.setState({selectedMeta: null});
			this.selectMeta({data: {metaLocal: 0}});
		}
	}

	toDecimal(rowData, column) {
		return toDecimal(rowData[column.field]);		
	}

	columns = [
		<TZMColumn sortable field="mes" key="mes" body={this.mesFormat} header="Mês" />,		
		<TZMColumn sortable field="rfid" body={this.toDecimal} key="Meta RFID" header="Meta RFID" />,		
		<TZMColumn key="acoes" header="Ações" style={{width: "5em", textAlign: "center"}} body={(meta) => {
			return (
				<div>
					{TZMUserData.hasRole("____META_E") ? <span key={0} title="Editar Meta" className="fa fa-edit ui-tzm-icon" onClick={() => this.selectMeta({data: meta})} /> : null}
					{TZMUserData.hasRole("____META_D") ? <span key={1} title="Remover Meta" className="fa fa-trash-alt ui-tzm-icon" onClick={() => this.deleteMeta({data: meta})} /> : null}
				</div>
			);
		}} />
	];

	deleteMeta = (meta) => {
		ModalRealm.showDialog(<TZMConfirm onYes={() => {
			this.metaService.excluir(meta).then(this.handleList);
		}} question="Tem certeza de que deseja excluir esta meta?" />);
	}

	render() {
		return (
			<TZMMainContent>
				<div className="ui-g">
					<div className="ui-g-12">
						<TZMPanel header={title}>
							<TZMForm>
								<div className="ui-g">
									<div className="ui-g-12">
										<TZMTextField autoFocus placeholder="Código" onChange={(event) => this.setState({params: {...this.state.params, meta: event.target.value}})} value={this.state.params.meta} label="Meta" />
									</div>
								</div>
							</TZMForm>
							<TZMPanelFooter>
								{TZMUserData.hasRole("____META_C") ? <TZMButton success={true} icon="fas fa-plus" label="Nova Meta" onClick={this.newMeta} /> : null}
								<TZMButton primary={true} icon="fas fa-search" label="Procurar" onClick={this.handleList} />
							</TZMPanelFooter>
						</TZMPanel>
						<div style={{height: "8px"}} />
						<TZMPanel header="Resultado da Pesquisa">
							<TZMTable value={this.state.list} paginator rows={50} children={this.columns} />
						</TZMPanel>
					</div>
				</div>
				{this.state.dialogs}
				<TZMShortcut active={TZMShortcut.modalCount === 0}
					onEnter={this.handleList}
					onCtrlH={this.toggleHidden}
					onCtrlN={this.newMeta}
				/>
				<span className="ui-tzm-dashboard-title">{empresaNome} - {title}</span>
				<ModalRealm />
			</TZMMainContent>
		);
	}

}
