
import moment from 'moment';
import 'moment/min/locales';
import React from "react";
import TZMButton from "../../../components/common/TZMButton";
import TZMCombobox from '../../../components/common/TZMCombobox';
import TZMTextField from "../../../components/common/TZMTextField";
import TZMConfirm from "../../../components/dialog/TZMConfirm";
import TZMDialog from "../../../components/dialog/TZMDialog";
import { ModalRealm } from '../../../components/modal/ModalRealm';
import TZMPanelFooter from "../../../components/panel/TZMPanelFooter";
import TZMShortcut from "../../../utilities/TZMShortcut";
import { ContaEmailService } from "../../servicos/ContaEmailService";

export default class EditarContaEmail extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			contaEmail: this.props.contaEmail,
			visible: true,
			alterado: false,
			params: {},
			contasEmail: " "
		};
		this.contaEmailService = new ContaEmailService();
		moment.locale('pt-BR');
	}

	handleUpdate = (event) => {
		const { contaEmail } = this.state;
		contaEmail[event.name] = event.value;
		this.setState({contaEmail, alterado: true});
	}

	validarContaEmail = () => {
		return true;
	}

	salvarContaEmail = () => {
		if (this.validarContaEmail()) {
			this.contaEmailService.salvar(this.state.contaEmail).then((response) => {
				this.setState({visible: false});
				if (this.props.onSave) {
					this.props.onSave(response);
				}
			});
		}
	}

	fecharPainel = () => {
		this.setState({ visible: false });
		if (this.props.onClose) {
			this.props.onClose(this.state.contaEmail);
		}
	}


	fecharContaEmail = () => {
		if (this.state.alterado) {
			ModalRealm.showDialog(<TZMConfirm onYes={this.fecharPainel} question="Fechar e descartar as alterações feitas?" />);
		} else {
			this.fecharPainel();
		}
	}

	reativarContaEmail = () => {
		this.contaEmailService.salvar({ ...this.state.ContaEmail, ativado: true }).then(() => this.fecharPainel("onDelete"));
	}

	desativarContaEmail = () => {
		ModalRealm.showDialog(<TZMConfirm onYes={() => {
			this.contaEmailService.excluir(this.state.ContaEmail).then(() => this.fecharPainel("onDelete"));
		}} question="Tem certeza de que deseja desativar esta conta de e-mail?" />);
	}

	finalidades = [
		{label: "Geral", value: "GERAL"}
	];

	render() {
		return (
			<TZMDialog style={{ width: "500px" }} visible={this.state.visible} modal header="Conta de E-mail" onHide={this.onHide} {...this.props}>
				<div className="ui-g">
					<TZMTextField col={8} name="nome" label="Nome" onChange={this.handleUpdate} value={this.state.contaEmail.nome} />
					<TZMCombobox col={4} name="finalidade" label="Finalidade" onChange={this.handleUpdate} value={this.state.contaEmail.finalidade}>
						{this.finalidades.map(f => <option key={f.value} value={f.value}>{f.label}</option>)}
					</TZMCombobox>
					<TZMTextField col={6} name="usuario" label="Usuário" onChange={this.handleUpdate} value={this.state.contaEmail.usuario} />
					<TZMTextField col={6} type="password" name="senha" label="Senha" onChange={this.handleUpdate} value={this.state.contaEmail.senha} />
					<TZMTextField col={6} name="host" label="Host" onChange={this.handleUpdate} value={this.state.contaEmail.host} />
					<TZMTextField col={6} name="porta" label="Porta" onChange={this.handleUpdate} value={this.state.contaEmail.porta} />
				</div>
				<TZMPanelFooter>
					{this.state.contaEmail.id != null && this.state.contaEmail.ativado ? <TZMButton style={{ float: "left" }} danger title="Desativar Conta de E-mail" icon="fas fa-trash-alt" onClick={this.desativarContaEmail} /> : null}
					{this.state.contaEmail.id != null && !this.state.contaEmail.ativado ? <TZMButton style={{ float: "left" }} title="Reativar Conta de E-mail" icon="fas fa-eye" onClick={this.reativarContaEmail} /> : null}
					<TZMButton success={true} label="Salvar" icon="fas fa-save" onClick={this.salvarContaEmail} />
					<TZMButton secondary={true} label="Fechar" icon="fas fa-times" onClick={this.fecharContaEmail} />
				</TZMPanelFooter>
				<TZMShortcut
					active={this.state.visible}
					onCtrlS={this.salvarContaEmail}
					onEscape={this.fecharContaEmail}
					onAlt1={() => this.selectTabIndex(0)}
				/>
			</TZMDialog>
		);
	}
	
}
